import { __ } from '@wordpress/i18n';
import { InspectorControls, AlignmentToolbar, BlockControls } from '@wordpress/block-editor';
import { TabPanel, PanelBody, PanelRow, TextControl, RangeControl, ToggleControl, SelectControl, Dashicon } from '@wordpress/components';

// Settings Components
import Title from '../../Components/Title';
import ColorsControl from '../../Components/ColorsControl';
import BtnGroup from '../../Components/BtnGroup';
import IconControl from '../../Components/IconControl';
import Typography from '../../Components/Typography';
import SpaceControl from '../../Components/SpaceControl';
import BorderControl from '../../Components/BorderControl';
import MultiShadowControl from '../../Components/MultiShadowControl';

import options from './Const/options';
const { targets, buttonTypes, buttonSizes, animationTypes, generalStyleTabs } = options;

const Settings = ({ attributes, setAttributes }) => {
	const { url, tooltip, target, isDownload, icon, type, isFullWidth, size, animationType, animationDuration, alignment, typography, colors, hovColors, padding, border, shadow } = attributes;

	return <>
		<InspectorControls>
			<TabPanel className='bPlTabPanel' activeClass='activeTab' tabs={generalStyleTabs}>{tab => <>
				{'general' === tab.name && <>
					<PanelBody className='bPlPanelBody help' title={__('Help', 'button-block')}>
						{/* <div className='helpItem'>
							<a href='https://bblockswp.com/docs/button-block/' target='_blank' rel='noreferrer'><Dashicon icon='book' size={23} />{__('Read Documentation', 'button-block')}</a>
						</div> */}

						<div className='helpItem rateUs'>
							<a href='https://wordpress.org/support/plugin/button-block/reviews/#new-post' target='_blank' rel='noreferrer'>
								<span><Dashicon icon='star-filled' size={23} />{__('Would you please rate us?', 'button-block')}</span>
								<span>{__('We are new and we need your help to grow!🙏', 'button-block')}</span>
							</a>
						</div>
					</PanelBody>


					<PanelBody className='bPlPanelBody' title={__('Button Settings', 'button-block')}>
						<Title mt='0'>{__('Button Url:', 'button-block')}</Title>
						<TextControl value={url} onChange={val => setAttributes({ url: val })} />
						<small>{__('For download, enter downloadable link', 'button-block')}</small>

						<Title>{__('Tooltip Text:', 'button-block')}</Title>
						<TextControl value={tooltip} onChange={val => setAttributes({ tooltip: val })} />
						<small>{__('If you want to add tooltip on button, type text rather then leave empty.', 'button-block')}</small>

						<PanelRow>
							<Title mt='0' mb='0'>{__('Link Target:', 'button-block')}</Title>
							<BtnGroup value={target} onChange={val => setAttributes({ target: val })} isTextIcon={true} options={targets} />
						</PanelRow>

						<ToggleControl className='mt20' label={__('Download Button', 'button-block')} checked={isDownload} onChange={val => setAttributes({ isDownload: val })} />
					</PanelBody>


					<PanelBody className='bPlPanelBody' title={__('Button Design', 'button-block')} initialOpen={false}>
						<IconControl icon={icon} onChange={val => setAttributes({ icon: val })} defaults={{ class: 'fa fa-paper-plane' }} isSize={false} isColor={false} />

						<PanelRow className='mt20'>
							<Title mt='0' mb='0'>{__('Button Type:', 'button-block')}</Title>
							<BtnGroup value={type} onChange={val => {
								setAttributes({ type: val });
								'flat' === val && setAttributes({ shadow: {} });
								'3d' === val && setAttributes({
									shadow: {
										shadow: [
											{ hOffset: '-5px', vOffset: '6px', blur: '2px', spreed: '0px', color: 'rgba(68, 39, 163, 0.57)', isInset: false },
											{ hOffset: '3px', vOffset: '-3px', blur: '0px', spreed: '0px', color: 'rgba(206, 191, 255, 1)', isInset: true }
										],
										styles: '-5px 6px 2px 0px rgba(68, 39, 163, 0.57) , 3px -3px 0px 0px rgba(206, 191, 255, 1) inset'
									}
								});
							}} isTextIcon={true} options={buttonTypes} />
						</PanelRow>

						<ToggleControl className='mt20' label={__('Button Full Width?', 'button-block')} checked={isFullWidth} onChange={val => setAttributes({ isFullWidth: val })} />

						<PanelRow>
							<Title mt='0' mb='0'>{__('Button Size:', 'button-block')}</Title>
							<SelectControl value={size} onChange={val => {
								setAttributes({ size: val });

								'small' === val && setAttributes({
									typography: { ...typography, fontSize: 12, styles: `${typography?.styles}; font-size: 12px;` },
									padding: { ...padding, vertical: '8px', horizontal: '16px', styles: '8px 16px' }
								});
								'medium' === val && setAttributes({
									typography: { ...typography, fontSize: 16, styles: `${typography?.styles}; font-size: 16px;` },
									padding: { ...padding, vertical: '12px', horizontal: '24px', styles: '12px 24px' }
								});
								'large' === val && setAttributes({
									typography: { ...typography, fontSize: 20, styles: `${typography?.styles}; font-size: 20px;` },
									padding: { ...padding, vertical: '14px', horizontal: '30px', styles: '14px 30px' }
								});
								'x-large' === val && setAttributes({
									typography: { ...typography, fontSize: 22, styles: `${typography?.styles}; font-size: 22px;` },
									padding: { ...padding, vertical: '16px', horizontal: '46px', styles: '16px 46px' }
								});
							}} options={buttonSizes} />
						</PanelRow>
					</PanelBody>


					<PanelBody className='bPlPanelBody' title={__('Animation', 'button-block')} initialOpen={false}>
						<PanelRow>
							<Title mt='0' mb='0'>{__('Type:', 'button-block')}</Title>
							<SelectControl value={animationType} onChange={val => setAttributes({ animationType: val })} options={animationTypes} />
						</PanelRow>

						<Title>{__('Duration (Speed) (s):', 'button-block')}</Title>
						<RangeControl value={animationDuration} onChange={val => setAttributes({ animationDuration: val })} min={0} max={3} step={0.05} />
						<small>{__('Animation duration of speed in seconds')}</small>
					</PanelBody>
				</>}


				{'style' === tab.name && <>
					<PanelBody className='bPlPanelBody' title={__('Button', 'button-block')}>
						<Typography typography={typography} onChange={val => setAttributes({ typography: val })} defaults={{ fontSize: 18, textDecoration: 'none' }} />

						<ColorsControl colors={colors} onChange={val => setAttributes({ colors: val })} defaults={{ color: '#fff', bg: '#4527a4' }} />

						<ColorsControl label={__('Hover Colors:', 'button-block')} colors={hovColors} onChange={val => setAttributes({ hovColors: val })} defaults={{ color: '#fff', bg: '#8344c5' }} />

						<SpaceControl className='mt20' label={__('Padding:', 'button-block')} space={padding} onChange={val => setAttributes({ padding: val })} defaults={{ vertical: '10px', horizontal: '20px' }} />

						<BorderControl className='mt20' border={border} onChange={val => setAttributes({ border: val })} defaults={{ radius: '5px' }} />

						<MultiShadowControl label={__('Shadow:', 'team-section')} value={shadow} onChange={val => setAttributes({ shadow: val })} type='box' />
					</PanelBody>
				</>}
			</>}</TabPanel>
		</InspectorControls>


		<BlockControls>
			<AlignmentToolbar value={alignment} onChange={val => setAttributes({ alignment: val })} describedBy={__('Button Alignment')} alignmentControls={[
				{ title: __('Button in left', 'button-block'), align: 'left', icon: 'align-left' },
				{ title: __('Button in center', 'button-block'), align: 'center', icon: 'align-center' },
				{ title: __('Button in right', 'button-block'), align: 'right', icon: 'align-right' }
			]} />
		</BlockControls>
	</>;
};
export default Settings;
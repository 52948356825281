/**
 * @props className (optional): 'mt20' (String)
 * @props label: 'Shadow' (String)
 * @props value: { type, shadow: [{hOffset, vOffset, blur, spreed, color, isInset}] } (Object)
 * @props onChange: (Function)
 * @props defaults (optional): { type, shadow: [{hOffset, vOffset, blur, spreed, color, isInset}] } (Object)
 * @return Shadow Properties (Object)
 */

import { useState, useEffect } from '@wordpress/element';
import { __ } from '@wordpress/i18n';
import { Dropdown, PanelRow, ToggleControl, __experimentalUnitControl as UnitControl, Button, Dashicon } from '@wordpress/components';

import Title from './Title';
import BColor from './BColor';
import BtnGroup from './BtnGroup';
import options from './Helper/options';
const { pxUnit, emUnit, remUnit } = options;

import icons from './Helper/icons';

const MultiShadowControl = props => {
	const { className = '', label = __('Shadow', 'bplugins'), value, onChange, type, defaults = {} } = props;

	const [activeIndex, setActiveIndex] = useState(0);

	const defaultVal = { shadow: [{ hOffset: '0px', vOffset: '0px', blur: '0px', spreed: '0px', color: '#7090b0', isInset: false }] }

	const updateValue = (property, val, child = false) => {
		const newProperty = [...getValue(property)];

		if (child) {
			newProperty[activeIndex][child] = val;
			onChange({ ...value, [property]: newProperty });
		} else {
			onChange({ ...value, [property]: val });
		}
	}

	const getDefault = (property, child = false) => child ? defaults?.[property]?.[activeIndex]?.[child] || defaultVal[property][0][child] : defaults?.[property] || defaultVal[property];
	const setDefault = (property, child = false) => child ? updateValue(property, getDefault(property, child), child) : updateValue(property, getDefault(property));

	const getValue = (property, child = false) => child ? value?.[property]?.[activeIndex]?.[child] || getDefault(property, child) : value?.[property] || getDefault(property);
	const resetValue = (property, child = false) => <Button icon='image-rotate' className='bPlResetVal' onClick={() => child ? setDefault(property, child) : setDefault(property)} />

	// Add styles
	useEffect(() => {
		let allStyles = '';

		getValue('shadow').map((item, index) => {
			const { hOffset, vOffset, blur, spreed, color, isInset } = item;
			const inset = isInset ? 'inset' : '';
			const offsetBlur = `${hOffset || ''} ${vOffset || ''} ${blur || ''}`;
			const isComa = index + 1 >= getValue('shadow').length ? '' : ', ';

			allStyles += 'text' === type ? `${offsetBlur} ${color || ''}${isComa}` : `${offsetBlur} ${spreed || ''} ${color || ''} ${inset}${isComa}`;
		});

		const initialObj = { shadow: getValue('shadow') }

		onChange({ ...value, ...initialObj, styles: allStyles.replace(/\s+/g, ' ').trim() });
	}, [getValue('shadow')]);


	const { hOffset = '', vOffset = '', blur = '', spreed = '', color = '', isInset = false } = getValue('shadow')[activeIndex] || {};

	return <PanelRow className={`bPlDropdown ${className}`}>
		<Title mt='5px'>{label}</Title>

		<Dropdown className='bPlDropdownContainer' contentClassName='bPlDropdownPopover' position='bottom right'
			renderToggle={({ isOpen, onToggle }) => <Button icon='edit' onClick={() => { onToggle(), setActiveIndex(0) }} aria-expanded={isOpen} />}
			renderContent={() => <>
				{1 < getValue('shadow').length && <PanelRow>
					<Title mt='5px'>{__('Shadow:', 'bplugins')}</Title>
					<BtnGroup value={activeIndex} onChange={val => setActiveIndex(val)} options={
						getValue('shadow').map((_, index) => ({ label: index + 1 + '', value: index })) || [{ label: 1, value: 0 }]
					} />
				</PanelRow>}

				{null !== activeIndex && <>
					<PanelRow>
						<UnitControl label={__('Horizontal Offset:', 'bplugins')} labelPosition='left' value={hOffset} onChange={val => updateValue('shadow', val, 'hOffset')} units={[pxUnit, emUnit, remUnit]} />
						{hOffset && hOffset !== getDefault('shadow', 'hOffset') && resetValue('shadow', 'hOffset')}
					</PanelRow>

					<PanelRow>
						<UnitControl label={__('Vertical Offset:', 'bplugins')} labelPosition='left' value={vOffset} onChange={val => updateValue('shadow', val, 'vOffset')} units={[pxUnit, emUnit, remUnit]} />
						{vOffset && vOffset !== getDefault('shadow', 'vOffset') && resetValue('shadow', 'vOffset')}
					</PanelRow>

					<PanelRow>
						<UnitControl label={__('Blur:', 'bplugins')} labelPosition='left' value={blur} onChange={val => updateValue('shadow', val, 'blur')} units={[pxUnit, emUnit, remUnit]} />
						{blur && blur !== getDefault('shadow', 'blur') && resetValue('shadow', 'blur')}
					</PanelRow>

					{'box' === type && <PanelRow>
						<UnitControl label={__('Spreed:', 'bplugins')} labelPosition='left' value={spreed} onChange={val => updateValue('shadow', val, 'spreed')} units={[pxUnit, emUnit, remUnit]} />
						{spreed && spreed !== getDefault('shadow', 'spreed') && resetValue('shadow', 'spreed')}
					</PanelRow>}

					<BColor label={__('Color:', 'bplugins')} value={color} onChange={val => updateValue('shadow', val, 'color')} defaultColor={getDefault('shadow', 'color')} />

					{'box' === type && <ToggleControl label={__('Shadow Inset?', 'bplugins')} checked={isInset} onChange={val => updateValue('shadow', val, 'isInset')} />}

					<PanelRow className='itemAction mt20'>
						<Button className='removeItem' label={__('Remove', 'bplugins')} onClick={e => {
							e.preventDefault();
							setActiveIndex(1 === getValue('shadow').length ? null : 0 === activeIndex ? 0 : activeIndex - 1);
							onChange({ ...value, ['shadow']: [...getValue('shadow').slice(0, activeIndex), ...getValue('shadow').slice(activeIndex + 1)] });
						}}><Dashicon icon='no' size={18} />{__('Remove', 'bplugins')}</Button>

						<Button className='duplicateItem' label={__('Duplicate', 'bplugins')} onClick={e => {
							e.preventDefault();
							setActiveIndex(getValue('shadow').length);
							onChange({ ...value, ['shadow']: [...getValue('shadow'), { ...getValue('shadow')[activeIndex] }] });
						}}>{icons.gearSettings}{__('Duplicate', 'bplugins')}</Button>
					</PanelRow>
				</>}

				<br />
				<div className='addItem'>
					<Button label={__('Add New Shadow', 'bplugins')} onClick={() => onChange({ ...value, ['shadow']: [...getValue('shadow'), defaultVal.shadow[0]] })}><Dashicon icon='plus' size={23} />{__('Add New Shadow', 'bplugins')}</Button>
				</div>
			</>}
		/>
	</PanelRow>;
};
export default MultiShadowControl;
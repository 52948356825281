const Style = ({ attributes, clientId }) => {
	const { isFullWidth, alignment, typography, colors, hovColors, padding, border, shadow } = attributes;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
			${typography?.googleFontLink ? `@import url(${typography?.googleFontLink});` : ''}

			#btnButton-${clientId}{
				text-align: ${alignment};
			}
			#btnButton-${clientId} .btnButton{
				${typography?.styles || 'font-size: 18px; font-weight: 600; text-decoration: none;'}
				${colors?.styles || 'color: #fff; background-color: #4527a4;'}
				width: ${isFullWidth ? '100%' : 'auto'};
				padding: ${padding?.styles || '10px 20px'};
				${border?.styles || 'border-radius: 5px;'}
				box-shadow: ${shadow?.styles || 'none'};
			}
			#btnButton-${clientId} .btnButton:hover{
				${hovColors?.styles || 'color: #fff; background-color: #8344c5;'}
			}
			`.replace(/\s+/g, ' ')
		}} />
	</>;
}
export default Style;
/**
 * @props className (optional): 'mt20' (String)
 * @props label (optional): 'Typography' (String)
 * @props colors (required): { color, bgType, bg, gradient } (Object)
 * @props onChange (required): (Function)
 * @props defaults (optional): { color, bgType, bg, gradient } (Object)
 */

import { useEffect } from '@wordpress/element';
import { __ } from '@wordpress/i18n';
import { Button, PanelRow, Dropdown, __experimentalGradientPicker, GradientPicker } from '@wordpress/components';
const Gradient = __experimentalGradientPicker || GradientPicker;

// Variables
import Title from './Title';
import BtnGroup from './BtnGroup';
import BColor from './BColor';
import options from './Helper/options';
const { gradients, bgTypes } = options;

const ColorsControl = props => {
	const { className = '', label = __('Colors:', 'bplugins'), colors, onChange, defaults = {} } = props;

	const defaultVal = { color: '#333', bgType: 'solid', bg: '#0000', gradient: 'linear-gradient(135deg, #4527a4, #8344c5)' }

	const getDefault = property => defaults?.[property] || defaultVal[property];

	const getValue = property => colors?.[property] || getDefault(property);
	const setValue = (property, val) => onChange({ ...colors, [property]: val });

	useEffect(() => {
		const allStyles = `${getValue('color') ? `color: ${getValue('color')};` : ''}
		${getValue('gradient') || getValue('bg') ? `background: ${'gradient' === getValue('bgType') ? getValue('gradient') : getValue('bg')};` : ''}`

		const initialObj = { color: getValue('color'), bgType: getValue('bgType'), bg: getValue('bg'), gradient: getValue('gradient') }

		onChange({ ...colors, ...initialObj, ['styles']: allStyles.replace(/\s+/g, ' ').trim() });
	}, [getValue('color'), getValue('bgType'), getValue('bg'), getValue('gradient')]);

	return <PanelRow className={`bPlDropdown ${className}`}>
		<Title mt='0' mb='0'>{label}</Title>

		<Dropdown className='bPlDropdownContainer' contentClassName='bPlDropdownPopover' position='bottom right'
			renderToggle={({ isOpen, onToggle }) => <Button icon='edit' onClick={onToggle} aria-expanded={isOpen} />}
			renderContent={() => <>
				<BColor label={__('Color:', 'bplugins')} value={getValue('color')} onChange={val => setValue('color', val)} defaultColor={getDefault('color')} />

				<PanelRow className='mt20'>
					<Title mt='0' mb='0'>{__('Background:', 'bplugins')}</Title>
					<BtnGroup value={getValue('bgType')} onChange={val => setValue('bgType', val)} options={bgTypes} size='small' />
				</PanelRow>

				{'gradient' === getValue('bgType') ? <Gradient value={getValue('gradient')} onChange={val => setValue('gradient', val)} gradients={gradients} /> : <BColor label={__('Background Color:', 'bplugins')} value={getValue('bg')} onChange={val => setValue('bg', val)} defaultColor={getDefault('bg')} />}
			</>}
		/>
	</PanelRow>;
};
export default ColorsControl;
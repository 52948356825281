export default [
	{ class: '', name: 'no-icon' },
	{ class: 'fab fa-500px', name: '500px' },
	{ class: 'fab fa-accessible-icon', name: 'accessible-icon' },
	{ class: 'fab fa-accusoft', name: 'accusoft' },
	{ class: 'fab fa-acquisitions-incorporated', name: 'acquisitions-incorporated' },
	{ class: 'fas fa-ad', name: 'ad' },
	{ class: 'fas fa-address-book', name: 'address-book' },
	{ class: 'far fa-address-book', name: 'address-book' },
	{ class: 'fas fa-address-card', name: 'address-card' },
	{ class: 'far fa-address-card', name: 'address-card' },
	{ class: 'fas fa-adjust', name: 'adjust' },
	{ class: 'fab fa-adn', name: 'adn' },
	{ class: 'fab fa-adversal', name: 'adversal' },
	{ class: 'fab fa-affiliatetheme', name: 'affiliatetheme' },
	{ class: 'fas fa-air-freshener', name: 'air-freshener' },
	{ class: 'fab fa-airbnb', name: 'airbnb' },
	{ class: 'fab fa-algolia', name: 'algolia' },
	{ class: 'fas fa-align-center', name: 'align-center' },
	{ class: 'fas fa-align-justify', name: 'align-justify' },
	{ class: 'fas fa-align-left', name: 'align-left' },
	{ class: 'fas fa-align-right', name: 'align-right' },
	{ class: 'fab fa-alipay', name: 'alipay' },
	{ class: 'fas fa-allergies', name: 'allergies' },
	{ class: 'fab fa-amazon', name: 'amazon' },
	{ class: 'fab fa-amazon-pay', name: 'amazon-pay' },
	{ class: 'fas fa-ambulance', name: 'ambulance' },
	{ class: 'fas fa-american-sign-language-interpreting', name: 'american-sign-language-interpreting' },
	{ class: 'fab fa-amilia', name: 'amilia' },
	{ class: 'fas fa-anchor', name: 'anchor' },
	{ class: 'fab fa-android', name: 'android' },
	{ class: 'fab fa-angellist', name: 'angellist' },
	{ class: 'fas fa-angle-double-down', name: 'angle-double-down' },
	{ class: 'fas fa-angle-double-left', name: 'angle-double-left' },
	{ class: 'fas fa-angle-double-right', name: 'angle-double-right' },
	{ class: 'fas fa-angle-double-up', name: 'angle-double-up' },
	{ class: 'fas fa-angle-down', name: 'angle-down' },
	{ class: 'fas fa-angle-left', name: 'angle-left' },
	{ class: 'fas fa-angle-right', name: 'angle-right' },
	{ class: 'fas fa-angle-up', name: 'angle-up' },
	{ class: 'fas fa-angry', name: 'angry' },
	{ class: 'far fa-angry', name: 'angry' },
	{ class: 'fab fa-angrycreative', name: 'angrycreative' },
	{ class: 'fab fa-angular', name: 'angular' },
	{ class: 'fas fa-ankh', name: 'ankh' },
	{ class: 'fab fa-app-store', name: 'app-store' },
	{ class: 'fab fa-app-store-ios', name: 'app-store-ios' },
	{ class: 'fab fa-apper', name: 'apper' },
	{ class: 'fab fa-apple', name: 'apple' },
	{ class: 'fas fa-apple-alt', name: 'apple-alt' },
	{ class: 'fab fa-apple-pay', name: 'apple-pay' },
	{ class: 'fas fa-archive', name: 'archive' },
	{ class: 'fas fa-archway', name: 'archway' },
	{ class: 'fas fa-arrow-alt-circle-down', name: 'arrow-alt-circle-down' },
	{ class: 'far fa-arrow-alt-circle-down', name: 'arrow-alt-circle-down' },
	{ class: 'fas fa-arrow-alt-circle-left', name: 'arrow-alt-circle-left' },
	{ class: 'far fa-arrow-alt-circle-left', name: 'arrow-alt-circle-left' },
	{ class: 'fas fa-arrow-alt-circle-right', name: 'arrow-alt-circle-right' },
	{ class: 'far fa-arrow-alt-circle-right', name: 'arrow-alt-circle-right' },
	{ class: 'fas fa-arrow-alt-circle-up', name: 'arrow-alt-circle-up' },
	{ class: 'far fa-arrow-alt-circle-up', name: 'arrow-alt-circle-up' },
	{ class: 'fas fa-arrow-circle-down', name: 'arrow-circle-down' },
	{ class: 'fas fa-arrow-circle-left', name: 'arrow-circle-left' },
	{ class: 'fas fa-arrow-circle-right', name: 'arrow-circle-right' },
	{ class: 'fas fa-arrow-circle-up', name: 'arrow-circle-up' },
	{ class: 'fas fa-arrow-down', name: 'arrow-down' },
	{ class: 'fas fa-arrow-left', name: 'arrow-left' },
	{ class: 'fas fa-arrow-right', name: 'arrow-right' },
	{ class: 'fas fa-arrow-up', name: 'arrow-up' },
	{ class: 'fas fa-arrows-alt', name: 'arrows-alt' },
	{ class: 'fas fa-arrows-alt-h', name: 'arrows-alt-h' },
	{ class: 'fas fa-arrows-alt-v', name: 'arrows-alt-v' },
	{ class: 'fab fa-artstation', name: 'artstation' },
	{ class: 'fas fa-assistive-listening-systems', name: 'assistive-listening-systems' },
	{ class: 'fas fa-asterisk', name: 'asterisk' },
	{ class: 'fab fa-asymmetrik', name: 'asymmetrik' },
	{ class: 'fas fa-at', name: 'at' },
	{ class: 'fas fa-atlas', name: 'atlas' },
	{ class: 'fab fa-atlassian', name: 'atlassian' },
	{ class: 'fas fa-atom', name: 'atom' },
	{ class: 'fab fa-audible', name: 'audible' },
	{ class: 'fas fa-audio-description', name: 'audio-description' },
	{ class: 'fab fa-autoprefixer', name: 'autoprefixer' },
	{ class: 'fab fa-avianex', name: 'avianex' },
	{ class: 'fab fa-aviato', name: 'aviato' },
	{ class: 'fas fa-award', name: 'award' },
	{ class: 'fab fa-aws', name: 'aws' },
	{ class: 'fas fa-baby', name: 'baby' },
	{ class: 'fas fa-baby-carriage', name: 'baby-carriage' },
	{ class: 'fas fa-backspace', name: 'backspace' },
	{ class: 'fas fa-backward', name: 'backward' },
	{ class: 'fas fa-bacon', name: 'bacon' },
	{ class: 'fas fa-bacteria', name: 'bacteria' },
	{ class: 'fas fa-bacterium', name: 'bacterium' },
	{ class: 'fas fa-bahai', name: 'bahai' },
	{ class: 'fas fa-balance-scale', name: 'balance-scale' },
	{ class: 'fas fa-balance-scale-left', name: 'balance-scale-left' },
	{ class: 'fas fa-balance-scale-right', name: 'balance-scale-right' },
	{ class: 'fas fa-ban', name: 'ban' },
	{ class: 'fas fa-band-aid', name: 'band-aid' },
	{ class: 'fab fa-bandcamp', name: 'bandcamp' },
	{ class: 'fas fa-barcode', name: 'barcode' },
	{ class: 'fas fa-bars', name: 'bars' },
	{ class: 'fas fa-baseball-ball', name: 'baseball-ball' },
	{ class: 'fas fa-basketball-ball', name: 'basketball-ball' },
	{ class: 'fas fa-bath', name: 'bath' },
	{ class: 'fas fa-battery-empty', name: 'battery-empty' },
	{ class: 'fas fa-battery-full', name: 'battery-full' },
	{ class: 'fas fa-battery-half', name: 'battery-half' },
	{ class: 'fas fa-battery-quarter', name: 'battery-quarter' },
	{ class: 'fas fa-battery-three-quarters', name: 'battery-three-quarters' },
	{ class: 'fab fa-battle-net', name: 'battle-net' },
	{ class: 'fas fa-bed', name: 'bed' },
	{ class: 'fas fa-beer', name: 'beer' },
	{ class: 'fab fa-behance', name: 'behance' },
	{ class: 'fab fa-behance-square', name: 'behance-square' },
	{ class: 'fas fa-bell', name: 'bell' },
	{ class: 'far fa-bell', name: 'bell' },
	{ class: 'fas fa-bell-slash', name: 'bell-slash' },
	{ class: 'far fa-bell-slash', name: 'bell-slash' },
	{ class: 'fas fa-bezier-curve', name: 'bezier-curve' },
	{ class: 'fas fa-bible', name: 'bible' },
	{ class: 'fas fa-bicycle', name: 'bicycle' },
	{ class: 'fas fa-biking', name: 'biking' },
	{ class: 'fab fa-bimobject', name: 'bimobject' },
	{ class: 'fas fa-binoculars', name: 'binoculars' },
	{ class: 'fas fa-biohazard', name: 'biohazard' },
	{ class: 'fas fa-birthday-cake', name: 'birthday-cake' },
	{ class: 'fab fa-bitbucket', name: 'bitbucket' },
	{ class: 'fab fa-bitcoin', name: 'bitcoin' },
	{ class: 'fab fa-bity', name: 'bity' },
	{ class: 'fab fa-black-tie', name: 'black-tie' },
	{ class: 'fab fa-blackberry', name: 'blackberry' },
	{ class: 'fas fa-blender', name: 'blender' },
	{ class: 'fas fa-blender-phone', name: 'blender-phone' },
	{ class: 'fas fa-blind', name: 'blind' },
	{ class: 'fas fa-blog', name: 'blog' },
	{ class: 'fab fa-blogger', name: 'blogger' },
	{ class: 'fab fa-blogger-b', name: 'blogger-b' },
	{ class: 'fab fa-bluetooth', name: 'bluetooth' },
	{ class: 'fab fa-bluetooth-b', name: 'bluetooth-b' },
	{ class: 'fas fa-bold', name: 'bold' },
	{ class: 'fas fa-bolt', name: 'bolt' },
	{ class: 'fas fa-bomb', name: 'bomb' },
	{ class: 'fas fa-bone', name: 'bone' },
	{ class: 'fas fa-bong', name: 'bong' },
	{ class: 'fas fa-book', name: 'book' },
	{ class: 'fas fa-book-dead', name: 'book-dead' },
	{ class: 'fas fa-book-medical', name: 'book-medical' },
	{ class: 'fas fa-book-open', name: 'book-open' },
	{ class: 'fas fa-book-reader', name: 'book-reader' },
	{ class: 'fas fa-bookmark', name: 'bookmark' },
	{ class: 'far fa-bookmark', name: 'bookmark' },
	{ class: 'fab fa-bootstrap', name: 'bootstrap' },
	{ class: 'fas fa-border-all', name: 'border-all' },
	{ class: 'fas fa-border-none', name: 'border-none' },
	{ class: 'fas fa-border-style', name: 'border-style' },
	{ class: 'fas fa-bowling-ball', name: 'bowling-ball' },
	{ class: 'fas fa-box', name: 'box' },
	{ class: 'fas fa-box-open', name: 'box-open' },
	{ class: 'fas fa-box-tissue', name: 'box-tissue' },
	{ class: 'fas fa-boxes', name: 'boxes' },
	{ class: 'fas fa-braille', name: 'braille' },
	{ class: 'fas fa-brain', name: 'brain' },
	{ class: 'fas fa-bread-slice', name: 'bread-slice' },
	{ class: 'fas fa-briefcase', name: 'briefcase' },
	{ class: 'fas fa-briefcase-medical', name: 'briefcase-medical' },
	{ class: 'fas fa-broadcast-tower', name: 'broadcast-tower' },
	{ class: 'fas fa-broom', name: 'broom' },
	{ class: 'fas fa-brush', name: 'brush' },
	{ class: 'fab fa-btc', name: 'btc' },
	{ class: 'fab fa-buffer', name: 'buffer' },
	{ class: 'fas fa-bug', name: 'bug' },
	{ class: 'fas fa-building', name: 'building' },
	{ class: 'far fa-building', name: 'building' },
	{ class: 'fas fa-bullhorn', name: 'bullhorn' },
	{ class: 'fas fa-bullseye', name: 'bullseye' },
	{ class: 'fas fa-burn', name: 'burn' },
	{ class: 'fab fa-buromobelexperte', name: 'buromobelexperte' },
	{ class: 'fas fa-bus', name: 'bus' },
	{ class: 'fas fa-bus-alt', name: 'bus-alt' },
	{ class: 'fas fa-business-time', name: 'business-time' },
	{ class: 'fab fa-buy-n-large', name: 'buy-n-large' },
	{ class: 'fab fa-buysellads', name: 'buysellads' },
	{ class: 'fas fa-calculator', name: 'calculator' },
	{ class: 'fas fa-calendar', name: 'calendar' },
	{ class: 'far fa-calendar', name: 'calendar' },
	{ class: 'fas fa-calendar-alt', name: 'calendar-alt' },
	{ class: 'far fa-calendar-alt', name: 'calendar-alt' },
	{ class: 'fas fa-calendar-check', name: 'calendar-check' },
	{ class: 'far fa-calendar-check', name: 'calendar-check' },
	{ class: 'fas fa-calendar-day', name: 'calendar-day' },
	{ class: 'fas fa-calendar-minus', name: 'calendar-minus' },
	{ class: 'far fa-calendar-minus', name: 'calendar-minus' },
	{ class: 'fas fa-calendar-plus', name: 'calendar-plus' },
	{ class: 'far fa-calendar-plus', name: 'calendar-plus' },
	{ class: 'fas fa-calendar-times', name: 'calendar-times' },
	{ class: 'far fa-calendar-times', name: 'calendar-times' },
	{ class: 'fas fa-calendar-week', name: 'calendar-week' },
	{ class: 'fas fa-camera', name: 'camera' },
	{ class: 'fas fa-camera-retro', name: 'camera-retro' },
	{ class: 'fas fa-campground', name: 'campground' },
	{ class: 'fab fa-canadian-maple-leaf', name: 'canadian-maple-leaf' },
	{ class: 'fas fa-candy-cane', name: 'candy-cane' },
	{ class: 'fas fa-cannabis', name: 'cannabis' },
	{ class: 'fas fa-capsules', name: 'capsules' },
	{ class: 'fas fa-car', name: 'car' },
	{ class: 'fas fa-car-alt', name: 'car-alt' },
	{ class: 'fas fa-car-battery', name: 'car-battery' },
	{ class: 'fas fa-car-crash', name: 'car-crash' },
	{ class: 'fas fa-car-side', name: 'car-side' },
	{ class: 'fas fa-caravan', name: 'caravan' },
	{ class: 'fas fa-caret-down', name: 'caret-down' },
	{ class: 'fas fa-caret-left', name: 'caret-left' },
	{ class: 'fas fa-caret-right', name: 'caret-right' },
	{ class: 'fas fa-caret-square-down', name: 'caret-square-down' },
	{ class: 'far fa-caret-square-down', name: 'caret-square-down' },
	{ class: 'fas fa-caret-square-left', name: 'caret-square-left' },
	{ class: 'far fa-caret-square-left', name: 'caret-square-left' },
	{ class: 'fas fa-caret-square-right', name: 'caret-square-right' },
	{ class: 'far fa-caret-square-right', name: 'caret-square-right' },
	{ class: 'fas fa-caret-square-up', name: 'caret-square-up' },
	{ class: 'far fa-caret-square-up', name: 'caret-square-up' },
	{ class: 'fas fa-caret-up', name: 'caret-up' },
	{ class: 'fas fa-carrot', name: 'carrot' },
	{ class: 'fas fa-cart-arrow-down', name: 'cart-arrow-down' },
	{ class: 'fas fa-cart-plus', name: 'cart-plus' },
	{ class: 'fas fa-cash-register', name: 'cash-register' },
	{ class: 'fas fa-cat', name: 'cat' },
	{ class: 'fab fa-cc-amazon-pay', name: 'cc-amazon-pay' },
	{ class: 'fab fa-cc-amex', name: 'cc-amex' },
	{ class: 'fab fa-cc-apple-pay', name: 'cc-apple-pay' },
	{ class: 'fab fa-cc-diners-club', name: 'cc-diners-club' },
	{ class: 'fab fa-cc-discover', name: 'cc-discover' },
	{ class: 'fab fa-cc-jcb', name: 'cc-jcb' },
	{ class: 'fab fa-cc-mastercard', name: 'cc-mastercard' },
	{ class: 'fab fa-cc-paypal', name: 'cc-paypal' },
	{ class: 'fab fa-cc-stripe', name: 'cc-stripe' },
	{ class: 'fab fa-cc-visa', name: 'cc-visa' },
	{ class: 'fab fa-centercode', name: 'centercode' },
	{ class: 'fab fa-centos', name: 'centos' },
	{ class: 'fas fa-certificate', name: 'certificate' },
	{ class: 'fas fa-chair', name: 'chair' },
	{ class: 'fas fa-chalkboard', name: 'chalkboard' },
	{ class: 'fas fa-chalkboard-teacher', name: 'chalkboard-teacher' },
	{ class: 'fas fa-charging-station', name: 'charging-station' },
	{ class: 'fas fa-chart-area', name: 'chart-area' },
	{ class: 'fas fa-chart-bar', name: 'chart-bar' },
	{ class: 'far fa-chart-bar', name: 'chart-bar' },
	{ class: 'fas fa-chart-line', name: 'chart-line' },
	{ class: 'fas fa-chart-pie', name: 'chart-pie' },
	{ class: 'fas fa-check', name: 'check' },
	{ class: 'fas fa-check-circle', name: 'check-circle' },
	{ class: 'far fa-check-circle', name: 'check-circle' },
	{ class: 'fas fa-check-double', name: 'check-double' },
	{ class: 'fas fa-check-square', name: 'check-square' },
	{ class: 'far fa-check-square', name: 'check-square' },
	{ class: 'fas fa-cheese', name: 'cheese' },
	{ class: 'fas fa-chess', name: 'chess' },
	{ class: 'fas fa-chess-bishop', name: 'chess-bishop' },
	{ class: 'fas fa-chess-board', name: 'chess-board' },
	{ class: 'fas fa-chess-king', name: 'chess-king' },
	{ class: 'fas fa-chess-knight', name: 'chess-knight' },
	{ class: 'fas fa-chess-pawn', name: 'chess-pawn' },
	{ class: 'fas fa-chess-queen', name: 'chess-queen' },
	{ class: 'fas fa-chess-rook', name: 'chess-rook' },
	{ class: 'fas fa-chevron-circle-down', name: 'chevron-circle-down' },
	{ class: 'fas fa-chevron-circle-left', name: 'chevron-circle-left' },
	{ class: 'fas fa-chevron-circle-right', name: 'chevron-circle-right' },
	{ class: 'fas fa-chevron-circle-up', name: 'chevron-circle-up' },
	{ class: 'fas fa-chevron-down', name: 'chevron-down' },
	{ class: 'fas fa-chevron-left', name: 'chevron-left' },
	{ class: 'fas fa-chevron-right', name: 'chevron-right' },
	{ class: 'fas fa-chevron-up', name: 'chevron-up' },
	{ class: 'fas fa-child', name: 'child' },
	{ class: 'fab fa-chrome', name: 'chrome' },
	{ class: 'fab fa-chromecast', name: 'chromecast' },
	{ class: 'fas fa-church', name: 'church' },
	{ class: 'fas fa-circle', name: 'circle' },
	{ class: 'far fa-circle', name: 'circle' },
	{ class: 'fas fa-circle-notch', name: 'circle-notch' },
	{ class: 'fas fa-city', name: 'city' },
	{ class: 'fas fa-clinic-medical', name: 'clinic-medical' },
	{ class: 'fas fa-clipboard', name: 'clipboard' },
	{ class: 'far fa-clipboard', name: 'clipboard' },
	{ class: 'fas fa-clipboard-check', name: 'clipboard-check' },
	{ class: 'fas fa-clipboard-list', name: 'clipboard-list' },
	{ class: 'fas fa-clock', name: 'clock' },
	{ class: 'far fa-clock', name: 'clock' },
	{ class: 'fas fa-clone', name: 'clone' },
	{ class: 'far fa-clone', name: 'clone' },
	{ class: 'fas fa-closed-captioning', name: 'closed-captioning' },
	{ class: 'far fa-closed-captioning', name: 'closed-captioning' },
	{ class: 'fas fa-cloud', name: 'cloud' },
	{ class: 'fas fa-cloud-download-alt', name: 'cloud-download-alt' },
	{ class: 'fas fa-cloud-meatball', name: 'cloud-meatball' },
	{ class: 'fas fa-cloud-moon', name: 'cloud-moon' },
	{ class: 'fas fa-cloud-moon-rain', name: 'cloud-moon-rain' },
	{ class: 'fas fa-cloud-rain', name: 'cloud-rain' },
	{ class: 'fas fa-cloud-showers-heavy', name: 'cloud-showers-heavy' },
	{ class: 'fas fa-cloud-sun', name: 'cloud-sun' },
	{ class: 'fas fa-cloud-sun-rain', name: 'cloud-sun-rain' },
	{ class: 'fas fa-cloud-upload-alt', name: 'cloud-upload-alt' },
	{ class: 'fab fa-cloudflare', name: 'cloudflare' },
	{ class: 'fab fa-cloudscale', name: 'cloudscale' },
	{ class: 'fab fa-cloudsmith', name: 'cloudsmith' },
	{ class: 'fab fa-cloudversify', name: 'cloudversify' },
	{ class: 'fas fa-cocktail', name: 'cocktail' },
	{ class: 'fas fa-code', name: 'code' },
	{ class: 'fas fa-code-branch', name: 'code-branch' },
	{ class: 'fab fa-codepen', name: 'codepen' },
	{ class: 'fab fa-codiepie', name: 'codiepie' },
	{ class: 'fas fa-coffee', name: 'coffee' },
	{ class: 'fas fa-cog', name: 'cog' },
	{ class: 'fas fa-cogs', name: 'cogs' },
	{ class: 'fas fa-coins', name: 'coins' },
	{ class: 'fas fa-columns', name: 'columns' },
	{ class: 'fas fa-comment', name: 'comment' },
	{ class: 'far fa-comment', name: 'comment' },
	{ class: 'fas fa-comment-alt', name: 'comment-alt' },
	{ class: 'far fa-comment-alt', name: 'comment-alt' },
	{ class: 'fas fa-comment-dollar', name: 'comment-dollar' },
	{ class: 'fas fa-comment-dots', name: 'comment-dots' },
	{ class: 'far fa-comment-dots', name: 'comment-dots' },
	{ class: 'fas fa-comment-medical', name: 'comment-medical' },
	{ class: 'fas fa-comment-slash', name: 'comment-slash' },
	{ class: 'fas fa-comments', name: 'comments' },
	{ class: 'far fa-comments', name: 'comments' },
	{ class: 'fas fa-comments-dollar', name: 'comments-dollar' },
	{ class: 'fas fa-compact-disc', name: 'compact-disc' },
	{ class: 'fas fa-compass', name: 'compass' },
	{ class: 'far fa-compass', name: 'compass' },
	{ class: 'fas fa-compress', name: 'compress' },
	{ class: 'fas fa-compress-alt', name: 'compress-alt' },
	{ class: 'fas fa-compress-arrows-alt', name: 'compress-arrows-alt' },
	{ class: 'fas fa-concierge-bell', name: 'concierge-bell' },
	{ class: 'fab fa-confluence', name: 'confluence' },
	{ class: 'fab fa-connectdevelop', name: 'connectdevelop' },
	{ class: 'fab fa-contao', name: 'contao' },
	{ class: 'fas fa-cookie', name: 'cookie' },
	{ class: 'fas fa-cookie-bite', name: 'cookie-bite' },
	{ class: 'fas fa-copy', name: 'copy' },
	{ class: 'far fa-copy', name: 'copy' },
	{ class: 'fas fa-copyright', name: 'copyright' },
	{ class: 'far fa-copyright', name: 'copyright' },
	{ class: 'fab fa-cotton-bureau', name: 'cotton-bureau' },
	{ class: 'fas fa-couch', name: 'couch' },
	{ class: 'fab fa-cpanel', name: 'cpanel' },
	{ class: 'fab fa-creative-commons', name: 'creative-commons' },
	{ class: 'fab fa-creative-commons-by', name: 'creative-commons-by' },
	{ class: 'fab fa-creative-commons-nc', name: 'creative-commons-nc' },
	{ class: 'fab fa-creative-commons-nc-eu', name: 'creative-commons-nc-eu' },
	{ class: 'fab fa-creative-commons-nc-jp', name: 'creative-commons-nc-jp' },
	{ class: 'fab fa-creative-commons-nd', name: 'creative-commons-nd' },
	{ class: 'fab fa-creative-commons-pd', name: 'creative-commons-pd' },
	{ class: 'fab fa-creative-commons-pd-alt', name: 'creative-commons-pd-alt' },
	{ class: 'fab fa-creative-commons-remix', name: 'creative-commons-remix' },
	{ class: 'fab fa-creative-commons-sa', name: 'creative-commons-sa' },
	{ class: 'fab fa-creative-commons-sampling', name: 'creative-commons-sampling' },
	{ class: 'fab fa-creative-commons-sampling-plus', name: 'creative-commons-sampling-plus' },
	{ class: 'fab fa-creative-commons-share', name: 'creative-commons-share' },
	{ class: 'fab fa-creative-commons-zero', name: 'creative-commons-zero' },
	{ class: 'fas fa-credit-card', name: 'credit-card' },
	{ class: 'far fa-credit-card', name: 'credit-card' },
	{ class: 'fab fa-critical-role', name: 'critical-role' },
	{ class: 'fas fa-crop', name: 'crop' },
	{ class: 'fas fa-crop-alt', name: 'crop-alt' },
	{ class: 'fas fa-cross', name: 'cross' },
	{ class: 'fas fa-crosshairs', name: 'crosshairs' },
	{ class: 'fas fa-crow', name: 'crow' },
	{ class: 'fas fa-crown', name: 'crown' },
	{ class: 'fas fa-crutch', name: 'crutch' },
	{ class: 'fab fa-css3', name: 'css3' },
	{ class: 'fab fa-css3-alt', name: 'css3-alt' },
	{ class: 'fas fa-cube', name: 'cube' },
	{ class: 'fas fa-cubes', name: 'cubes' },
	{ class: 'fas fa-cut', name: 'cut' },
	{ class: 'fab fa-cuttlefish', name: 'cuttlefish' },
	{ class: 'fab fa-d-and-d', name: 'd-and-d' },
	{ class: 'fab fa-d-and-d-beyond', name: 'd-and-d-beyond' },
	{ class: 'fab fa-dailymotion', name: 'dailymotion' },
	{ class: 'fab fa-dashcube', name: 'dashcube' },
	{ class: 'fas fa-database', name: 'database' },
	{ class: 'fas fa-deaf', name: 'deaf' },
	{ class: 'fab fa-deezer', name: 'deezer' },
	{ class: 'fab fa-delicious', name: 'delicious' },
	{ class: 'fas fa-democrat', name: 'democrat' },
	{ class: 'fab fa-deploydog', name: 'deploydog' },
	{ class: 'fab fa-deskpro', name: 'deskpro' },
	{ class: 'fas fa-desktop', name: 'desktop' },
	{ class: 'fab fa-dev', name: 'dev' },
	{ class: 'fab fa-deviantart', name: 'deviantart' },
	{ class: 'fas fa-dharmachakra', name: 'dharmachakra' },
	{ class: 'fab fa-dhl', name: 'dhl' },
	{ class: 'fas fa-diagnoses', name: 'diagnoses' },
	{ class: 'fab fa-diaspora', name: 'diaspora' },
	{ class: 'fas fa-dice', name: 'dice' },
	{ class: 'fas fa-dice-d20', name: 'dice-d20' },
	{ class: 'fas fa-dice-d6', name: 'dice-d6' },
	{ class: 'fas fa-dice-five', name: 'dice-five' },
	{ class: 'fas fa-dice-four', name: 'dice-four' },
	{ class: 'fas fa-dice-one', name: 'dice-one' },
	{ class: 'fas fa-dice-six', name: 'dice-six' },
	{ class: 'fas fa-dice-three', name: 'dice-three' },
	{ class: 'fas fa-dice-two', name: 'dice-two' },
	{ class: 'fab fa-digg', name: 'digg' },
	{ class: 'fab fa-digital-ocean', name: 'digital-ocean' },
	{ class: 'fas fa-digital-tachograph', name: 'digital-tachograph' },
	{ class: 'fas fa-directions', name: 'directions' },
	{ class: 'fab fa-discord', name: 'discord' },
	{ class: 'fab fa-discourse', name: 'discourse' },
	{ class: 'fas fa-disease', name: 'disease' },
	{ class: 'fas fa-divide', name: 'divide' },
	{ class: 'fas fa-dizzy', name: 'dizzy' },
	{ class: 'far fa-dizzy', name: 'dizzy' },
	{ class: 'fas fa-dna', name: 'dna' },
	{ class: 'fab fa-dochub', name: 'dochub' },
	{ class: 'fab fa-docker', name: 'docker' },
	{ class: 'fas fa-dog', name: 'dog' },
	{ class: 'fas fa-dollar-sign', name: 'dollar-sign' },
	{ class: 'fas fa-dolly', name: 'dolly' },
	{ class: 'fas fa-dolly-flatbed', name: 'dolly-flatbed' },
	{ class: 'fas fa-donate', name: 'donate' },
	{ class: 'fas fa-door-closed', name: 'door-closed' },
	{ class: 'fas fa-door-open', name: 'door-open' },
	{ class: 'fas fa-dot-circle', name: 'dot-circle' },
	{ class: 'far fa-dot-circle', name: 'dot-circle' },
	{ class: 'fas fa-dove', name: 'dove' },
	{ class: 'fas fa-download', name: 'download' },
	{ class: 'fab fa-draft2digital', name: 'draft2digital' },
	{ class: 'fas fa-drafting-compass', name: 'drafting-compass' },
	{ class: 'fas fa-dragon', name: 'dragon' },
	{ class: 'fas fa-draw-polygon', name: 'draw-polygon' },
	{ class: 'fab fa-dribbble', name: 'dribbble' },
	{ class: 'fab fa-dribbble-square', name: 'dribbble-square' },
	{ class: 'fab fa-dropbox', name: 'dropbox' },
	{ class: 'fas fa-drum', name: 'drum' },
	{ class: 'fas fa-drum-steelpan', name: 'drum-steelpan' },
	{ class: 'fas fa-drumstick-bite', name: 'drumstick-bite' },
	{ class: 'fab fa-drupal', name: 'drupal' },
	{ class: 'fas fa-dumbbell', name: 'dumbbell' },
	{ class: 'fas fa-dumpster', name: 'dumpster' },
	{ class: 'fas fa-dumpster-fire', name: 'dumpster-fire' },
	{ class: 'fas fa-dungeon', name: 'dungeon' },
	{ class: 'fab fa-dyalog', name: 'dyalog' },
	{ class: 'fab fa-earlybirds', name: 'earlybirds' },
	{ class: 'fab fa-ebay', name: 'ebay' },
	{ class: 'fab fa-edge', name: 'edge' },
	{ class: 'fab fa-edge-legacy', name: 'edge-legacy' },
	{ class: 'fas fa-edit', name: 'edit' },
	{ class: 'far fa-edit', name: 'edit' },
	{ class: 'fas fa-egg', name: 'egg' },
	{ class: 'fas fa-eject', name: 'eject' },
	{ class: 'fab fa-elementor', name: 'elementor' },
	{ class: 'fas fa-ellipsis-h', name: 'ellipsis-h' },
	{ class: 'fas fa-ellipsis-v', name: 'ellipsis-v' },
	{ class: 'fab fa-ello', name: 'ello' },
	{ class: 'fab fa-ember', name: 'ember' },
	{ class: 'fab fa-empire', name: 'empire' },
	{ class: 'fas fa-envelope', name: 'envelope' },
	{ class: 'far fa-envelope', name: 'envelope' },
	{ class: 'fas fa-envelope-open', name: 'envelope-open' },
	{ class: 'far fa-envelope-open', name: 'envelope-open' },
	{ class: 'fas fa-envelope-open-text', name: 'envelope-open-text' },
	{ class: 'fas fa-envelope-square', name: 'envelope-square' },
	{ class: 'fab fa-envira', name: 'envira' },
	{ class: 'fas fa-equals', name: 'equals' },
	{ class: 'fas fa-eraser', name: 'eraser' },
	{ class: 'fab fa-erlang', name: 'erlang' },
	{ class: 'fab fa-ethereum', name: 'ethereum' },
	{ class: 'fas fa-ethernet', name: 'ethernet' },
	{ class: 'fab fa-etsy', name: 'etsy' },
	{ class: 'fas fa-euro-sign', name: 'euro-sign' },
	{ class: 'fab fa-evernote', name: 'evernote' },
	{ class: 'fas fa-exchange-alt', name: 'exchange-alt' },
	{ class: 'fas fa-exclamation', name: 'exclamation' },
	{ class: 'fas fa-exclamation-circle', name: 'exclamation-circle' },
	{ class: 'fas fa-exclamation-triangle', name: 'exclamation-triangle' },
	{ class: 'fas fa-expand', name: 'expand' },
	{ class: 'fas fa-expand-alt', name: 'expand-alt' },
	{ class: 'fas fa-expand-arrows-alt', name: 'expand-arrows-alt' },
	{ class: 'fab fa-expeditedssl', name: 'expeditedssl' },
	{ class: 'fas fa-external-link-alt', name: 'external-link-alt' },
	{ class: 'fas fa-external-link-square-alt', name: 'external-link-square-alt' },
	{ class: 'fas fa-eye', name: 'eye' },
	{ class: 'far fa-eye', name: 'eye' },
	{ class: 'fas fa-eye-dropper', name: 'eye-dropper' },
	{ class: 'fas fa-eye-slash', name: 'eye-slash' },
	{ class: 'far fa-eye-slash', name: 'eye-slash' },
	{ class: 'fab fa-facebook', name: 'facebook' },
	{ class: 'fab fa-facebook-f', name: 'facebook-f' },
	{ class: 'fab fa-facebook-messenger', name: 'facebook-messenger' },
	{ class: 'fab fa-facebook-square', name: 'facebook-square' },
	{ class: 'fas fa-fan', name: 'fan' },
	{ class: 'fab fa-fantasy-flight-games', name: 'fantasy-flight-games' },
	{ class: 'fas fa-fast-backward', name: 'fast-backward' },
	{ class: 'fas fa-fast-forward', name: 'fast-forward' },
	{ class: 'fas fa-faucet', name: 'faucet' },
	{ class: 'fas fa-fax', name: 'fax' },
	{ class: 'fas fa-feather', name: 'feather' },
	{ class: 'fas fa-feather-alt', name: 'feather-alt' },
	{ class: 'fab fa-fedex', name: 'fedex' },
	{ class: 'fab fa-fedora', name: 'fedora' },
	{ class: 'fas fa-female', name: 'female' },
	{ class: 'fas fa-fighter-jet', name: 'fighter-jet' },
	{ class: 'fab fa-figma', name: 'figma' },
	{ class: 'fas fa-file', name: 'file' },
	{ class: 'far fa-file', name: 'file' },
	{ class: 'fas fa-file-alt', name: 'file-alt' },
	{ class: 'far fa-file-alt', name: 'file-alt' },
	{ class: 'fas fa-file-archive', name: 'file-archive' },
	{ class: 'far fa-file-archive', name: 'file-archive' },
	{ class: 'fas fa-file-audio', name: 'file-audio' },
	{ class: 'far fa-file-audio', name: 'file-audio' },
	{ class: 'fas fa-file-code', name: 'file-code' },
	{ class: 'far fa-file-code', name: 'file-code' },
	{ class: 'fas fa-file-contract', name: 'file-contract' },
	{ class: 'fas fa-file-csv', name: 'file-csv' },
	{ class: 'fas fa-file-download', name: 'file-download' },
	{ class: 'fas fa-file-excel', name: 'file-excel' },
	{ class: 'far fa-file-excel', name: 'file-excel' },
	{ class: 'fas fa-file-export', name: 'file-export' },
	{ class: 'fas fa-file-image', name: 'file-image' },
	{ class: 'far fa-file-image', name: 'file-image' },
	{ class: 'fas fa-file-import', name: 'file-import' },
	{ class: 'fas fa-file-invoice', name: 'file-invoice' },
	{ class: 'fas fa-file-invoice-dollar', name: 'file-invoice-dollar' },
	{ class: 'fas fa-file-medical', name: 'file-medical' },
	{ class: 'fas fa-file-medical-alt', name: 'file-medical-alt' },
	{ class: 'fas fa-file-pdf', name: 'file-pdf' },
	{ class: 'far fa-file-pdf', name: 'file-pdf' },
	{ class: 'fas fa-file-powerpoint', name: 'file-powerpoint' },
	{ class: 'far fa-file-powerpoint', name: 'file-powerpoint' },
	{ class: 'fas fa-file-prescription', name: 'file-prescription' },
	{ class: 'fas fa-file-signature', name: 'file-signature' },
	{ class: 'fas fa-file-upload', name: 'file-upload' },
	{ class: 'fas fa-file-video', name: 'file-video' },
	{ class: 'far fa-file-video', name: 'file-video' },
	{ class: 'fas fa-file-word', name: 'file-word' },
	{ class: 'far fa-file-word', name: 'file-word' },
	{ class: 'fas fa-fill', name: 'fill' },
	{ class: 'fas fa-fill-drip', name: 'fill-drip' },
	{ class: 'fas fa-film', name: 'film' },
	{ class: 'fas fa-filter', name: 'filter' },
	{ class: 'fas fa-fingerprint', name: 'fingerprint' },
	{ class: 'fas fa-fire', name: 'fire' },
	{ class: 'fas fa-fire-alt', name: 'fire-alt' },
	{ class: 'fas fa-fire-extinguisher', name: 'fire-extinguisher' },
	{ class: 'fab fa-firefox', name: 'firefox' },
	{ class: 'fab fa-firefox-browser', name: 'firefox-browser' },
	{ class: 'fas fa-first-aid', name: 'first-aid' },
	{ class: 'fab fa-first-order', name: 'first-order' },
	{ class: 'fab fa-first-order-alt', name: 'first-order-alt' },
	{ class: 'fab fa-firstdraft', name: 'firstdraft' },
	{ class: 'fas fa-fish', name: 'fish' },
	{ class: 'fas fa-fist-raised', name: 'fist-raised' },
	{ class: 'fas fa-flag', name: 'flag' },
	{ class: 'far fa-flag', name: 'flag' },
	{ class: 'fas fa-flag-checkered', name: 'flag-checkered' },
	{ class: 'fas fa-flag-usa', name: 'flag-usa' },
	{ class: 'fas fa-flask', name: 'flask' },
	{ class: 'fab fa-flickr', name: 'flickr' },
	{ class: 'fab fa-flipboard', name: 'flipboard' },
	{ class: 'fas fa-flushed', name: 'flushed' },
	{ class: 'far fa-flushed', name: 'flushed' },
	{ class: 'fab fa-fly', name: 'fly' },
	{ class: 'fas fa-folder', name: 'folder' },
	{ class: 'far fa-folder', name: 'folder' },
	{ class: 'fas fa-folder-minus', name: 'folder-minus' },
	{ class: 'fas fa-folder-open', name: 'folder-open' },
	{ class: 'far fa-folder-open', name: 'folder-open' },
	{ class: 'fas fa-folder-plus', name: 'folder-plus' },
	{ class: 'fas fa-font', name: 'font' },
	{ class: 'fab fa-font-awesome', name: 'font-awesome' },
	{ class: 'fab fa-font-awesome-alt', name: 'font-awesome-alt' },
	{ class: 'fab fa-font-awesome-flag', name: 'font-awesome-flag' },
	{ class: 'fab fa-fonticons', name: 'fonticons' },
	{ class: 'fab fa-fonticons-fi', name: 'fonticons-fi' },
	{ class: 'fas fa-football-ball', name: 'football-ball' },
	{ class: 'fab fa-fort-awesome', name: 'fort-awesome' },
	{ class: 'fab fa-fort-awesome-alt', name: 'fort-awesome-alt' },
	{ class: 'fab fa-forumbee', name: 'forumbee' },
	{ class: 'fas fa-forward', name: 'forward' },
	{ class: 'fab fa-foursquare', name: 'foursquare' },
	{ class: 'fab fa-free-code-camp', name: 'free-code-camp' },
	{ class: 'fab fa-freebsd', name: 'freebsd' },
	{ class: 'fas fa-frog', name: 'frog' },
	{ class: 'fas fa-frown', name: 'frown' },
	{ class: 'far fa-frown', name: 'frown' },
	{ class: 'fas fa-frown-open', name: 'frown-open' },
	{ class: 'far fa-frown-open', name: 'frown-open' },
	{ class: 'fab fa-fulcrum', name: 'fulcrum' },
	{ class: 'fas fa-funnel-dollar', name: 'funnel-dollar' },
	{ class: 'fas fa-futbol', name: 'futbol' },
	{ class: 'far fa-futbol', name: 'futbol' },
	{ class: 'fab fa-galactic-republic', name: 'galactic-republic' },
	{ class: 'fab fa-galactic-senate', name: 'galactic-senate' },
	{ class: 'fas fa-gamepad', name: 'gamepad' },
	{ class: 'fas fa-gas-pump', name: 'gas-pump' },
	{ class: 'fas fa-gavel', name: 'gavel' },
	{ class: 'fas fa-gem', name: 'gem' },
	{ class: 'far fa-gem', name: 'gem' },
	{ class: 'fas fa-genderless', name: 'genderless' },
	{ class: 'fab fa-get-pocket', name: 'get-pocket' },
	{ class: 'fab fa-gg', name: 'gg' },
	{ class: 'fab fa-gg-circle', name: 'gg-circle' },
	{ class: 'fas fa-ghost', name: 'ghost' },
	{ class: 'fas fa-gift', name: 'gift' },
	{ class: 'fas fa-gifts', name: 'gifts' },
	{ class: 'fab fa-git', name: 'git' },
	{ class: 'fab fa-git-alt', name: 'git-alt' },
	{ class: 'fab fa-git-square', name: 'git-square' },
	{ class: 'fab fa-github', name: 'github' },
	{ class: 'fab fa-github-alt', name: 'github-alt' },
	{ class: 'fab fa-github-square', name: 'github-square' },
	{ class: 'fab fa-gitkraken', name: 'gitkraken' },
	{ class: 'fab fa-gitlab', name: 'gitlab' },
	{ class: 'fab fa-gitter', name: 'gitter' },
	{ class: 'fas fa-glass-cheers', name: 'glass-cheers' },
	{ class: 'fas fa-glass-martini', name: 'glass-martini' },
	{ class: 'fas fa-glass-martini-alt', name: 'glass-martini-alt' },
	{ class: 'fas fa-glass-whiskey', name: 'glass-whiskey' },
	{ class: 'fas fa-glasses', name: 'glasses' },
	{ class: 'fab fa-glide', name: 'glide' },
	{ class: 'fab fa-glide-g', name: 'glide-g' },
	{ class: 'fas fa-globe', name: 'globe' },
	{ class: 'fas fa-globe-africa', name: 'globe-africa' },
	{ class: 'fas fa-globe-americas', name: 'globe-americas' },
	{ class: 'fas fa-globe-asia', name: 'globe-asia' },
	{ class: 'fas fa-globe-europe', name: 'globe-europe' },
	{ class: 'fab fa-gofore', name: 'gofore' },
	{ class: 'fas fa-golf-ball', name: 'golf-ball' },
	{ class: 'fab fa-goodreads', name: 'goodreads' },
	{ class: 'fab fa-goodreads-g', name: 'goodreads-g' },
	{ class: 'fab fa-google', name: 'google' },
	{ class: 'fab fa-google-drive', name: 'google-drive' },
	{ class: 'fab fa-google-pay', name: 'google-pay' },
	{ class: 'fab fa-google-play', name: 'google-play' },
	{ class: 'fab fa-google-plus', name: 'google-plus' },
	{ class: 'fab fa-google-plus-g', name: 'google-plus-g' },
	{ class: 'fab fa-google-plus-square', name: 'google-plus-square' },
	{ class: 'fab fa-google-wallet', name: 'google-wallet' },
	{ class: 'fas fa-gopuram', name: 'gopuram' },
	{ class: 'fas fa-graduation-cap', name: 'graduation-cap' },
	{ class: 'fab fa-gratipay', name: 'gratipay' },
	{ class: 'fab fa-grav', name: 'grav' },
	{ class: 'fas fa-greater-than', name: 'greater-than' },
	{ class: 'fas fa-greater-than-equal', name: 'greater-than-equal' },
	{ class: 'fas fa-grimace', name: 'grimace' },
	{ class: 'far fa-grimace', name: 'grimace' },
	{ class: 'fas fa-grin', name: 'grin' },
	{ class: 'far fa-grin', name: 'grin' },
	{ class: 'fas fa-grin-alt', name: 'grin-alt' },
	{ class: 'far fa-grin-alt', name: 'grin-alt' },
	{ class: 'fas fa-grin-beam', name: 'grin-beam' },
	{ class: 'far fa-grin-beam', name: 'grin-beam' },
	{ class: 'fas fa-grin-beam-sweat', name: 'grin-beam-sweat' },
	{ class: 'far fa-grin-beam-sweat', name: 'grin-beam-sweat' },
	{ class: 'fas fa-grin-hearts', name: 'grin-hearts' },
	{ class: 'far fa-grin-hearts', name: 'grin-hearts' },
	{ class: 'fas fa-grin-squint', name: 'grin-squint' },
	{ class: 'far fa-grin-squint', name: 'grin-squint' },
	{ class: 'fas fa-grin-squint-tears', name: 'grin-squint-tears' },
	{ class: 'far fa-grin-squint-tears', name: 'grin-squint-tears' },
	{ class: 'fas fa-grin-stars', name: 'grin-stars' },
	{ class: 'far fa-grin-stars', name: 'grin-stars' },
	{ class: 'fas fa-grin-tears', name: 'grin-tears' },
	{ class: 'far fa-grin-tears', name: 'grin-tears' },
	{ class: 'fas fa-grin-tongue', name: 'grin-tongue' },
	{ class: 'far fa-grin-tongue', name: 'grin-tongue' },
	{ class: 'fas fa-grin-tongue-squint', name: 'grin-tongue-squint' },
	{ class: 'far fa-grin-tongue-squint', name: 'grin-tongue-squint' },
	{ class: 'fas fa-grin-tongue-wink', name: 'grin-tongue-wink' },
	{ class: 'far fa-grin-tongue-wink', name: 'grin-tongue-wink' },
	{ class: 'fas fa-grin-wink', name: 'grin-wink' },
	{ class: 'far fa-grin-wink', name: 'grin-wink' },
	{ class: 'fas fa-grip-horizontal', name: 'grip-horizontal' },
	{ class: 'fas fa-grip-lines', name: 'grip-lines' },
	{ class: 'fas fa-grip-lines-vertical', name: 'grip-lines-vertical' },
	{ class: 'fas fa-grip-vertical', name: 'grip-vertical' },
	{ class: 'fab fa-gripfire', name: 'gripfire' },
	{ class: 'fab fa-grunt', name: 'grunt' },
	{ class: 'fab fa-guilded', name: 'guilded' },
	{ class: 'fas fa-guitar', name: 'guitar' },
	{ class: 'fab fa-gulp', name: 'gulp' },
	{ class: 'fas fa-h-square', name: 'h-square' },
	{ class: 'fab fa-hacker-news', name: 'hacker-news' },
	{ class: 'fab fa-hacker-news-square', name: 'hacker-news-square' },
	{ class: 'fab fa-hackerrank', name: 'hackerrank' },
	{ class: 'fas fa-hamburger', name: 'hamburger' },
	{ class: 'fas fa-hammer', name: 'hammer' },
	{ class: 'fas fa-hamsa', name: 'hamsa' },
	{ class: 'fas fa-hand-holding', name: 'hand-holding' },
	{ class: 'fas fa-hand-holding-heart', name: 'hand-holding-heart' },
	{ class: 'fas fa-hand-holding-medical', name: 'hand-holding-medical' },
	{ class: 'fas fa-hand-holding-usd', name: 'hand-holding-usd' },
	{ class: 'fas fa-hand-holding-water', name: 'hand-holding-water' },
	{ class: 'fas fa-hand-lizard', name: 'hand-lizard' },
	{ class: 'far fa-hand-lizard', name: 'hand-lizard' },
	{ class: 'fas fa-hand-middle-finger', name: 'hand-middle-finger' },
	{ class: 'fas fa-hand-paper', name: 'hand-paper' },
	{ class: 'far fa-hand-paper', name: 'hand-paper' },
	{ class: 'fas fa-hand-peace', name: 'hand-peace' },
	{ class: 'far fa-hand-peace', name: 'hand-peace' },
	{ class: 'fas fa-hand-point-down', name: 'hand-point-down' },
	{ class: 'far fa-hand-point-down', name: 'hand-point-down' },
	{ class: 'fas fa-hand-point-left', name: 'hand-point-left' },
	{ class: 'far fa-hand-point-left', name: 'hand-point-left' },
	{ class: 'fas fa-hand-point-right', name: 'hand-point-right' },
	{ class: 'far fa-hand-point-right', name: 'hand-point-right' },
	{ class: 'fas fa-hand-point-up', name: 'hand-point-up' },
	{ class: 'far fa-hand-point-up', name: 'hand-point-up' },
	{ class: 'fas fa-hand-pointer', name: 'hand-pointer' },
	{ class: 'far fa-hand-pointer', name: 'hand-pointer' },
	{ class: 'fas fa-hand-rock', name: 'hand-rock' },
	{ class: 'far fa-hand-rock', name: 'hand-rock' },
	{ class: 'fas fa-hand-scissors', name: 'hand-scissors' },
	{ class: 'far fa-hand-scissors', name: 'hand-scissors' },
	{ class: 'fas fa-hand-sparkles', name: 'hand-sparkles' },
	{ class: 'fas fa-hand-spock', name: 'hand-spock' },
	{ class: 'far fa-hand-spock', name: 'hand-spock' },
	{ class: 'fas fa-hands', name: 'hands' },
	{ class: 'fas fa-hands-helping', name: 'hands-helping' },
	{ class: 'fas fa-hands-wash', name: 'hands-wash' },
	{ class: 'fas fa-handshake', name: 'handshake' },
	{ class: 'far fa-handshake', name: 'handshake' },
	{ class: 'fas fa-handshake-alt-slash', name: 'handshake-alt-slash' },
	{ class: 'fas fa-handshake-slash', name: 'handshake-slash' },
	{ class: 'fas fa-hanukiah', name: 'hanukiah' },
	{ class: 'fas fa-hard-hat', name: 'hard-hat' },
	{ class: 'fas fa-hashtag', name: 'hashtag' },
	{ class: 'fas fa-hat-cowboy', name: 'hat-cowboy' },
	{ class: 'fas fa-hat-cowboy-side', name: 'hat-cowboy-side' },
	{ class: 'fas fa-hat-wizard', name: 'hat-wizard' },
	{ class: 'fas fa-hdd', name: 'hdd' },
	{ class: 'far fa-hdd', name: 'hdd' },
	{ class: 'fas fa-head-side-cough', name: 'head-side-cough' },
	{ class: 'fas fa-head-side-cough-slash', name: 'head-side-cough-slash' },
	{ class: 'fas fa-head-side-mask', name: 'head-side-mask' },
	{ class: 'fas fa-head-side-virus', name: 'head-side-virus' },
	{ class: 'fas fa-heading', name: 'heading' },
	{ class: 'fas fa-headphones', name: 'headphones' },
	{ class: 'fas fa-headphones-alt', name: 'headphones-alt' },
	{ class: 'fas fa-headset', name: 'headset' },
	{ class: 'fas fa-heart', name: 'heart' },
	{ class: 'far fa-heart', name: 'heart' },
	{ class: 'fas fa-heart-broken', name: 'heart-broken' },
	{ class: 'fas fa-heartbeat', name: 'heartbeat' },
	{ class: 'fas fa-helicopter', name: 'helicopter' },
	{ class: 'fas fa-highlighter', name: 'highlighter' },
	{ class: 'fas fa-hiking', name: 'hiking' },
	{ class: 'fas fa-hippo', name: 'hippo' },
	{ class: 'fab fa-hips', name: 'hips' },
	{ class: 'fab fa-hire-a-helper', name: 'hire-a-helper' },
	{ class: 'fas fa-history', name: 'history' },
	{ class: 'fab fa-hive', name: 'hive' },
	{ class: 'fas fa-hockey-puck', name: 'hockey-puck' },
	{ class: 'fas fa-holly-berry', name: 'holly-berry' },
	{ class: 'fas fa-home', name: 'home' },
	{ class: 'fab fa-hooli', name: 'hooli' },
	{ class: 'fab fa-hornbill', name: 'hornbill' },
	{ class: 'fas fa-horse', name: 'horse' },
	{ class: 'fas fa-horse-head', name: 'horse-head' },
	{ class: 'fas fa-hospital', name: 'hospital' },
	{ class: 'far fa-hospital', name: 'hospital' },
	{ class: 'fas fa-hospital-alt', name: 'hospital-alt' },
	{ class: 'fas fa-hospital-symbol', name: 'hospital-symbol' },
	{ class: 'fas fa-hospital-user', name: 'hospital-user' },
	{ class: 'fas fa-hot-tub', name: 'hot-tub' },
	{ class: 'fas fa-hotdog', name: 'hotdog' },
	{ class: 'fas fa-hotel', name: 'hotel' },
	{ class: 'fab fa-hotjar', name: 'hotjar' },
	{ class: 'fas fa-hourglass', name: 'hourglass' },
	{ class: 'far fa-hourglass', name: 'hourglass' },
	{ class: 'fas fa-hourglass-end', name: 'hourglass-end' },
	{ class: 'fas fa-hourglass-half', name: 'hourglass-half' },
	{ class: 'fas fa-hourglass-start', name: 'hourglass-start' },
	{ class: 'fas fa-house-damage', name: 'house-damage' },
	{ class: 'fas fa-house-user', name: 'house-user' },
	{ class: 'fab fa-houzz', name: 'houzz' },
	{ class: 'fas fa-hryvnia', name: 'hryvnia' },
	{ class: 'fab fa-html5', name: 'html5' },
	{ class: 'fab fa-hubspot', name: 'hubspot' },
	{ class: 'fas fa-i-cursor', name: 'i-cursor' },
	{ class: 'fas fa-ice-cream', name: 'ice-cream' },
	{ class: 'fas fa-icicles', name: 'icicles' },
	{ class: 'fas fa-icons', name: 'icons' },
	{ class: 'fas fa-id-badge', name: 'id-badge' },
	{ class: 'far fa-id-badge', name: 'id-badge' },
	{ class: 'fas fa-id-card', name: 'id-card' },
	{ class: 'far fa-id-card', name: 'id-card' },
	{ class: 'fas fa-id-card-alt', name: 'id-card-alt' },
	{ class: 'fab fa-ideal', name: 'ideal' },
	{ class: 'fas fa-igloo', name: 'igloo' },
	{ class: 'fas fa-image', name: 'image' },
	{ class: 'far fa-image', name: 'image' },
	{ class: 'fas fa-images', name: 'images' },
	{ class: 'far fa-images', name: 'images' },
	{ class: 'fab fa-imdb', name: 'imdb' },
	{ class: 'fas fa-inbox', name: 'inbox' },
	{ class: 'fas fa-indent', name: 'indent' },
	{ class: 'fas fa-industry', name: 'industry' },
	{ class: 'fas fa-infinity', name: 'infinity' },
	{ class: 'fas fa-info', name: 'info' },
	{ class: 'fas fa-info-circle', name: 'info-circle' },
	{ class: 'fab fa-innosoft', name: 'innosoft' },
	{ class: 'fab fa-instagram', name: 'instagram' },
	{ class: 'fab fa-instagram-square', name: 'instagram-square' },
	{ class: 'fab fa-instalod', name: 'instalod' },
	{ class: 'fab fa-intercom', name: 'intercom' },
	{ class: 'fab fa-internet-explorer', name: 'internet-explorer' },
	{ class: 'fab fa-invision', name: 'invision' },
	{ class: 'fab fa-ioxhost', name: 'ioxhost' },
	{ class: 'fas fa-italic', name: 'italic' },
	{ class: 'fab fa-itch-io', name: 'itch-io' },
	{ class: 'fab fa-itunes', name: 'itunes' },
	{ class: 'fab fa-itunes-note', name: 'itunes-note' },
	{ class: 'fab fa-java', name: 'java' },
	{ class: 'fas fa-jedi', name: 'jedi' },
	{ class: 'fab fa-jedi-order', name: 'jedi-order' },
	{ class: 'fab fa-jenkins', name: 'jenkins' },
	{ class: 'fab fa-jira', name: 'jira' },
	{ class: 'fab fa-joget', name: 'joget' },
	{ class: 'fas fa-joint', name: 'joint' },
	{ class: 'fab fa-joomla', name: 'joomla' },
	{ class: 'fas fa-journal-whills', name: 'journal-whills' },
	{ class: 'fab fa-js', name: 'js' },
	{ class: 'fab fa-js-square', name: 'js-square' },
	{ class: 'fab fa-jsfiddle', name: 'jsfiddle' },
	{ class: 'fas fa-kaaba', name: 'kaaba' },
	{ class: 'fab fa-kaggle', name: 'kaggle' },
	{ class: 'fas fa-key', name: 'key' },
	{ class: 'fab fa-keybase', name: 'keybase' },
	{ class: 'fas fa-keyboard', name: 'keyboard' },
	{ class: 'far fa-keyboard', name: 'keyboard' },
	{ class: 'fab fa-keycdn', name: 'keycdn' },
	{ class: 'fas fa-khanda', name: 'khanda' },
	{ class: 'fab fa-kickstarter', name: 'kickstarter' },
	{ class: 'fab fa-kickstarter-k', name: 'kickstarter-k' },
	{ class: 'fas fa-kiss', name: 'kiss' },
	{ class: 'far fa-kiss', name: 'kiss' },
	{ class: 'fas fa-kiss-beam', name: 'kiss-beam' },
	{ class: 'far fa-kiss-beam', name: 'kiss-beam' },
	{ class: 'fas fa-kiss-wink-heart', name: 'kiss-wink-heart' },
	{ class: 'far fa-kiss-wink-heart', name: 'kiss-wink-heart' },
	{ class: 'fas fa-kiwi-bird', name: 'kiwi-bird' },
	{ class: 'fab fa-korvue', name: 'korvue' },
	{ class: 'fas fa-landmark', name: 'landmark' },
	{ class: 'fas fa-language', name: 'language' },
	{ class: 'fas fa-laptop', name: 'laptop' },
	{ class: 'fas fa-laptop-code', name: 'laptop-code' },
	{ class: 'fas fa-laptop-house', name: 'laptop-house' },
	{ class: 'fas fa-laptop-medical', name: 'laptop-medical' },
	{ class: 'fab fa-laravel', name: 'laravel' },
	{ class: 'fab fa-lastfm', name: 'lastfm' },
	{ class: 'fab fa-lastfm-square', name: 'lastfm-square' },
	{ class: 'fas fa-laugh', name: 'laugh' },
	{ class: 'far fa-laugh', name: 'laugh' },
	{ class: 'fas fa-laugh-beam', name: 'laugh-beam' },
	{ class: 'far fa-laugh-beam', name: 'laugh-beam' },
	{ class: 'fas fa-laugh-squint', name: 'laugh-squint' },
	{ class: 'far fa-laugh-squint', name: 'laugh-squint' },
	{ class: 'fas fa-laugh-wink', name: 'laugh-wink' },
	{ class: 'far fa-laugh-wink', name: 'laugh-wink' },
	{ class: 'fas fa-layer-group', name: 'layer-group' },
	{ class: 'fas fa-leaf', name: 'leaf' },
	{ class: 'fab fa-leanpub', name: 'leanpub' },
	{ class: 'fas fa-lemon', name: 'lemon' },
	{ class: 'far fa-lemon', name: 'lemon' },
	{ class: 'fab fa-less', name: 'less' },
	{ class: 'fas fa-less-than', name: 'less-than' },
	{ class: 'fas fa-less-than-equal', name: 'less-than-equal' },
	{ class: 'fas fa-level-down-alt', name: 'level-down-alt' },
	{ class: 'fas fa-level-up-alt', name: 'level-up-alt' },
	{ class: 'fas fa-life-ring', name: 'life-ring' },
	{ class: 'far fa-life-ring', name: 'life-ring' },
	{ class: 'fas fa-lightbulb', name: 'lightbulb' },
	{ class: 'far fa-lightbulb', name: 'lightbulb' },
	{ class: 'fab fa-line', name: 'line' },
	{ class: 'fas fa-link', name: 'link' },
	{ class: 'fab fa-linkedin', name: 'linkedin' },
	{ class: 'fab fa-linkedin-in', name: 'linkedin-in' },
	{ class: 'fab fa-linode', name: 'linode' },
	{ class: 'fab fa-linux', name: 'linux' },
	{ class: 'fas fa-lira-sign', name: 'lira-sign' },
	{ class: 'fas fa-list', name: 'list' },
	{ class: 'fas fa-list-alt', name: 'list-alt' },
	{ class: 'far fa-list-alt', name: 'list-alt' },
	{ class: 'fas fa-list-ol', name: 'list-ol' },
	{ class: 'fas fa-list-ul', name: 'list-ul' },
	{ class: 'fas fa-location-arrow', name: 'location-arrow' },
	{ class: 'fas fa-lock', name: 'lock' },
	{ class: 'fas fa-lock-open', name: 'lock-open' },
	{ class: 'fas fa-long-arrow-alt-down', name: 'long-arrow-alt-down' },
	{ class: 'fas fa-long-arrow-alt-left', name: 'long-arrow-alt-left' },
	{ class: 'fas fa-long-arrow-alt-right', name: 'long-arrow-alt-right' },
	{ class: 'fas fa-long-arrow-alt-up', name: 'long-arrow-alt-up' },
	{ class: 'fas fa-low-vision', name: 'low-vision' },
	{ class: 'fas fa-luggage-cart', name: 'luggage-cart' },
	{ class: 'fas fa-lungs', name: 'lungs' },
	{ class: 'fas fa-lungs-virus', name: 'lungs-virus' },
	{ class: 'fab fa-lyft', name: 'lyft' },
	{ class: 'fab fa-magento', name: 'magento' },
	{ class: 'fas fa-magic', name: 'magic' },
	{ class: 'fas fa-magnet', name: 'magnet' },
	{ class: 'fas fa-mail-bulk', name: 'mail-bulk' },
	{ class: 'fab fa-mailchimp', name: 'mailchimp' },
	{ class: 'fas fa-male', name: 'male' },
	{ class: 'fab fa-mandalorian', name: 'mandalorian' },
	{ class: 'fas fa-map', name: 'map' },
	{ class: 'far fa-map', name: 'map' },
	{ class: 'fas fa-map-marked', name: 'map-marked' },
	{ class: 'fas fa-map-marked-alt', name: 'map-marked-alt' },
	{ class: 'fas fa-map-marker', name: 'map-marker' },
	{ class: 'fas fa-map-marker-alt', name: 'map-marker-alt' },
	{ class: 'fas fa-map-pin', name: 'map-pin' },
	{ class: 'fas fa-map-signs', name: 'map-signs' },
	{ class: 'fab fa-markdown', name: 'markdown' },
	{ class: 'fas fa-marker', name: 'marker' },
	{ class: 'fas fa-mars', name: 'mars' },
	{ class: 'fas fa-mars-double', name: 'mars-double' },
	{ class: 'fas fa-mars-stroke', name: 'mars-stroke' },
	{ class: 'fas fa-mars-stroke-h', name: 'mars-stroke-h' },
	{ class: 'fas fa-mars-stroke-v', name: 'mars-stroke-v' },
	{ class: 'fas fa-mask', name: 'mask' },
	{ class: 'fab fa-mastodon', name: 'mastodon' },
	{ class: 'fab fa-maxcdn', name: 'maxcdn' },
	{ class: 'fab fa-mdb', name: 'mdb' },
	{ class: 'fas fa-medal', name: 'medal' },
	{ class: 'fab fa-medapps', name: 'medapps' },
	{ class: 'fab fa-medium', name: 'medium' },
	{ class: 'fab fa-medium-m', name: 'medium-m' },
	{ class: 'fas fa-medkit', name: 'medkit' },
	{ class: 'fab fa-medrt', name: 'medrt' },
	{ class: 'fab fa-meetup', name: 'meetup' },
	{ class: 'fab fa-megaport', name: 'megaport' },
	{ class: 'fas fa-meh', name: 'meh' },
	{ class: 'far fa-meh', name: 'meh' },
	{ class: 'fas fa-meh-blank', name: 'meh-blank' },
	{ class: 'far fa-meh-blank', name: 'meh-blank' },
	{ class: 'fas fa-meh-rolling-eyes', name: 'meh-rolling-eyes' },
	{ class: 'far fa-meh-rolling-eyes', name: 'meh-rolling-eyes' },
	{ class: 'fas fa-memory', name: 'memory' },
	{ class: 'fab fa-mendeley', name: 'mendeley' },
	{ class: 'fas fa-menorah', name: 'menorah' },
	{ class: 'fas fa-mercury', name: 'mercury' },
	{ class: 'fas fa-meteor', name: 'meteor' },
	{ class: 'fab fa-microblog', name: 'microblog' },
	{ class: 'fas fa-microchip', name: 'microchip' },
	{ class: 'fas fa-microphone', name: 'microphone' },
	{ class: 'fas fa-microphone-alt', name: 'microphone-alt' },
	{ class: 'fas fa-microphone-alt-slash', name: 'microphone-alt-slash' },
	{ class: 'fas fa-microphone-slash', name: 'microphone-slash' },
	{ class: 'fas fa-microscope', name: 'microscope' },
	{ class: 'fab fa-microsoft', name: 'microsoft' },
	{ class: 'fas fa-minus', name: 'minus' },
	{ class: 'fas fa-minus-circle', name: 'minus-circle' },
	{ class: 'fas fa-minus-square', name: 'minus-square' },
	{ class: 'far fa-minus-square', name: 'minus-square' },
	{ class: 'fas fa-mitten', name: 'mitten' },
	{ class: 'fab fa-mix', name: 'mix' },
	{ class: 'fab fa-mixcloud', name: 'mixcloud' },
	{ class: 'fab fa-mixer', name: 'mixer' },
	{ class: 'fab fa-mizuni', name: 'mizuni' },
	{ class: 'fas fa-mobile', name: 'mobile' },
	{ class: 'fas fa-mobile-alt', name: 'mobile-alt' },
	{ class: 'fab fa-modx', name: 'modx' },
	{ class: 'fab fa-monero', name: 'monero' },
	{ class: 'fas fa-money-bill', name: 'money-bill' },
	{ class: 'fas fa-money-bill-alt', name: 'money-bill-alt' },
	{ class: 'far fa-money-bill-alt', name: 'money-bill-alt' },
	{ class: 'fas fa-money-bill-wave', name: 'money-bill-wave' },
	{ class: 'fas fa-money-bill-wave-alt', name: 'money-bill-wave-alt' },
	{ class: 'fas fa-money-check', name: 'money-check' },
	{ class: 'fas fa-money-check-alt', name: 'money-check-alt' },
	{ class: 'fas fa-monument', name: 'monument' },
	{ class: 'fas fa-moon', name: 'moon' },
	{ class: 'far fa-moon', name: 'moon' },
	{ class: 'fas fa-mortar-pestle', name: 'mortar-pestle' },
	{ class: 'fas fa-mosque', name: 'mosque' },
	{ class: 'fas fa-motorcycle', name: 'motorcycle' },
	{ class: 'fas fa-mountain', name: 'mountain' },
	{ class: 'fas fa-mouse', name: 'mouse' },
	{ class: 'fas fa-mouse-pointer', name: 'mouse-pointer' },
	{ class: 'fas fa-mug-hot', name: 'mug-hot' },
	{ class: 'fas fa-music', name: 'music' },
	{ class: 'fab fa-napster', name: 'napster' },
	{ class: 'fab fa-neos', name: 'neos' },
	{ class: 'fas fa-network-wired', name: 'network-wired' },
	{ class: 'fas fa-neuter', name: 'neuter' },
	{ class: 'fas fa-newspaper', name: 'newspaper' },
	{ class: 'far fa-newspaper', name: 'newspaper' },
	{ class: 'fab fa-nimblr', name: 'nimblr' },
	{ class: 'fab fa-node', name: 'node' },
	{ class: 'fab fa-node-js', name: 'node-js' },
	{ class: 'fas fa-not-equal', name: 'not-equal' },
	{ class: 'fas fa-notes-medical', name: 'notes-medical' },
	{ class: 'fab fa-npm', name: 'npm' },
	{ class: 'fab fa-ns8', name: 'ns8' },
	{ class: 'fab fa-nutritionix', name: 'nutritionix' },
	{ class: 'fas fa-object-group', name: 'object-group' },
	{ class: 'far fa-object-group', name: 'object-group' },
	{ class: 'fas fa-object-ungroup', name: 'object-ungroup' },
	{ class: 'far fa-object-ungroup', name: 'object-ungroup' },
	{ class: 'fab fa-octopus-deploy', name: 'octopus-deploy' },
	{ class: 'fab fa-odnoklassniki', name: 'odnoklassniki' },
	{ class: 'fab fa-odnoklassniki-square', name: 'odnoklassniki-square' },
	{ class: 'fas fa-oil-can', name: 'oil-can' },
	{ class: 'fab fa-old-republic', name: 'old-republic' },
	{ class: 'fas fa-om', name: 'om' },
	{ class: 'fab fa-opencart', name: 'opencart' },
	{ class: 'fab fa-openid', name: 'openid' },
	{ class: 'fab fa-opera', name: 'opera' },
	{ class: 'fab fa-optin-monster', name: 'optin-monster' },
	{ class: 'fab fa-orcid', name: 'orcid' },
	{ class: 'fab fa-osi', name: 'osi' },
	{ class: 'fas fa-otter', name: 'otter' },
	{ class: 'fas fa-outdent', name: 'outdent' },
	{ class: 'fab fa-page4', name: 'page4' },
	{ class: 'fab fa-pagelines', name: 'pagelines' },
	{ class: 'fas fa-pager', name: 'pager' },
	{ class: 'fas fa-paint-brush', name: 'paint-brush' },
	{ class: 'fas fa-paint-roller', name: 'paint-roller' },
	{ class: 'fas fa-palette', name: 'palette' },
	{ class: 'fab fa-palfed', name: 'palfed' },
	{ class: 'fas fa-pallet', name: 'pallet' },
	{ class: 'fas fa-paper-plane', name: 'paper-plane' },
	{ class: 'far fa-paper-plane', name: 'paper-plane' },
	{ class: 'fas fa-paperclip', name: 'paperclip' },
	{ class: 'fas fa-parachute-box', name: 'parachute-box' },
	{ class: 'fas fa-paragraph', name: 'paragraph' },
	{ class: 'fas fa-parking', name: 'parking' },
	{ class: 'fas fa-passport', name: 'passport' },
	{ class: 'fas fa-pastafarianism', name: 'pastafarianism' },
	{ class: 'fas fa-paste', name: 'paste' },
	{ class: 'fab fa-patreon', name: 'patreon' },
	{ class: 'fas fa-pause', name: 'pause' },
	{ class: 'fas fa-pause-circle', name: 'pause-circle' },
	{ class: 'far fa-pause-circle', name: 'pause-circle' },
	{ class: 'fas fa-paw', name: 'paw' },
	{ class: 'fab fa-paypal', name: 'paypal' },
	{ class: 'fas fa-peace', name: 'peace' },
	{ class: 'fas fa-pen', name: 'pen' },
	{ class: 'fas fa-pen-alt', name: 'pen-alt' },
	{ class: 'fas fa-pen-fancy', name: 'pen-fancy' },
	{ class: 'fas fa-pen-nib', name: 'pen-nib' },
	{ class: 'fas fa-pen-square', name: 'pen-square' },
	{ class: 'fas fa-pencil-alt', name: 'pencil-alt' },
	{ class: 'fas fa-pencil-ruler', name: 'pencil-ruler' },
	{ class: 'fab fa-penny-arcade', name: 'penny-arcade' },
	{ class: 'fas fa-people-arrows', name: 'people-arrows' },
	{ class: 'fas fa-people-carry', name: 'people-carry' },
	{ class: 'fas fa-pepper-hot', name: 'pepper-hot' },
	{ class: 'fab fa-perbyte', name: 'perbyte' },
	{ class: 'fas fa-percent', name: 'percent' },
	{ class: 'fas fa-percentage', name: 'percentage' },
	{ class: 'fab fa-periscope', name: 'periscope' },
	{ class: 'fas fa-person-booth', name: 'person-booth' },
	{ class: 'fab fa-phabricator', name: 'phabricator' },
	{ class: 'fab fa-phoenix-framework', name: 'phoenix-framework' },
	{ class: 'fab fa-phoenix-squadron', name: 'phoenix-squadron' },
	{ class: 'fas fa-phone', name: 'phone' },
	{ class: 'fas fa-phone-alt', name: 'phone-alt' },
	{ class: 'fas fa-phone-slash', name: 'phone-slash' },
	{ class: 'fas fa-phone-square', name: 'phone-square' },
	{ class: 'fas fa-phone-square-alt', name: 'phone-square-alt' },
	{ class: 'fas fa-phone-volume', name: 'phone-volume' },
	{ class: 'fas fa-photo-video', name: 'photo-video' },
	{ class: 'fab fa-php', name: 'php' },
	{ class: 'fab fa-pied-piper', name: 'pied-piper' },
	{ class: 'fab fa-pied-piper-alt', name: 'pied-piper-alt' },
	{ class: 'fab fa-pied-piper-hat', name: 'pied-piper-hat' },
	{ class: 'fab fa-pied-piper-pp', name: 'pied-piper-pp' },
	{ class: 'fab fa-pied-piper-square', name: 'pied-piper-square' },
	{ class: 'fas fa-piggy-bank', name: 'piggy-bank' },
	{ class: 'fas fa-pills', name: 'pills' },
	{ class: 'fab fa-pinterest', name: 'pinterest' },
	{ class: 'fab fa-pinterest-p', name: 'pinterest-p' },
	{ class: 'fab fa-pinterest-square', name: 'pinterest-square' },
	{ class: 'fas fa-pizza-slice', name: 'pizza-slice' },
	{ class: 'fas fa-place-of-worship', name: 'place-of-worship' },
	{ class: 'fas fa-plane', name: 'plane' },
	{ class: 'fas fa-plane-arrival', name: 'plane-arrival' },
	{ class: 'fas fa-plane-departure', name: 'plane-departure' },
	{ class: 'fas fa-plane-slash', name: 'plane-slash' },
	{ class: 'fas fa-play', name: 'play' },
	{ class: 'fas fa-play-circle', name: 'play-circle' },
	{ class: 'far fa-play-circle', name: 'play-circle' },
	{ class: 'fab fa-playstation', name: 'playstation' },
	{ class: 'fas fa-plug', name: 'plug' },
	{ class: 'fas fa-plus', name: 'plus' },
	{ class: 'fas fa-plus-circle', name: 'plus-circle' },
	{ class: 'fas fa-plus-square', name: 'plus-square' },
	{ class: 'far fa-plus-square', name: 'plus-square' },
	{ class: 'fas fa-podcast', name: 'podcast' },
	{ class: 'fas fa-poll', name: 'poll' },
	{ class: 'fas fa-poll-h', name: 'poll-h' },
	{ class: 'fas fa-poo', name: 'poo' },
	{ class: 'fas fa-poo-storm', name: 'poo-storm' },
	{ class: 'fas fa-poop', name: 'poop' },
	{ class: 'fas fa-portrait', name: 'portrait' },
	{ class: 'fas fa-pound-sign', name: 'pound-sign' },
	{ class: 'fas fa-power-off', name: 'power-off' },
	{ class: 'fas fa-pray', name: 'pray' },
	{ class: 'fas fa-praying-hands', name: 'praying-hands' },
	{ class: 'fas fa-prescription', name: 'prescription' },
	{ class: 'fas fa-prescription-bottle', name: 'prescription-bottle' },
	{ class: 'fas fa-prescription-bottle-alt', name: 'prescription-bottle-alt' },
	{ class: 'fas fa-print', name: 'print' },
	{ class: 'fas fa-procedures', name: 'procedures' },
	{ class: 'fab fa-product-hunt', name: 'product-hunt' },
	{ class: 'fas fa-project-diagram', name: 'project-diagram' },
	{ class: 'fas fa-pump-medical', name: 'pump-medical' },
	{ class: 'fas fa-pump-soap', name: 'pump-soap' },
	{ class: 'fab fa-pushed', name: 'pushed' },
	{ class: 'fas fa-puzzle-piece', name: 'puzzle-piece' },
	{ class: 'fab fa-python', name: 'python' },
	{ class: 'fab fa-qq', name: 'qq' },
	{ class: 'fas fa-qrcode', name: 'qrcode' },
	{ class: 'fas fa-question', name: 'question' },
	{ class: 'fas fa-question-circle', name: 'question-circle' },
	{ class: 'far fa-question-circle', name: 'question-circle' },
	{ class: 'fas fa-quidditch', name: 'quidditch' },
	{ class: 'fab fa-quinscape', name: 'quinscape' },
	{ class: 'fab fa-quora', name: 'quora' },
	{ class: 'fas fa-quote-left', name: 'quote-left' },
	{ class: 'fas fa-quote-right', name: 'quote-right' },
	{ class: 'fas fa-quran', name: 'quran' },
	{ class: 'fab fa-r-project', name: 'r-project' },
	{ class: 'fas fa-radiation', name: 'radiation' },
	{ class: 'fas fa-radiation-alt', name: 'radiation-alt' },
	{ class: 'fas fa-rainbow', name: 'rainbow' },
	{ class: 'fas fa-random', name: 'random' },
	{ class: 'fab fa-raspberry-pi', name: 'raspberry-pi' },
	{ class: 'fab fa-ravelry', name: 'ravelry' },
	{ class: 'fab fa-react', name: 'react' },
	{ class: 'fab fa-reacteurope', name: 'reacteurope' },
	{ class: 'fab fa-readme', name: 'readme' },
	{ class: 'fab fa-rebel', name: 'rebel' },
	{ class: 'fas fa-receipt', name: 'receipt' },
	{ class: 'fas fa-record-vinyl', name: 'record-vinyl' },
	{ class: 'fas fa-recycle', name: 'recycle' },
	{ class: 'fab fa-red-river', name: 'red-river' },
	{ class: 'fab fa-reddit', name: 'reddit' },
	{ class: 'fab fa-reddit-alien', name: 'reddit-alien' },
	{ class: 'fab fa-reddit-square', name: 'reddit-square' },
	{ class: 'fab fa-redhat', name: 'redhat' },
	{ class: 'fas fa-redo', name: 'redo' },
	{ class: 'fas fa-redo-alt', name: 'redo-alt' },
	{ class: 'fas fa-registered', name: 'registered' },
	{ class: 'far fa-registered', name: 'registered' },
	{ class: 'fas fa-remove-format', name: 'remove-format' },
	{ class: 'fab fa-renren', name: 'renren' },
	{ class: 'fas fa-reply', name: 'reply' },
	{ class: 'fas fa-reply-all', name: 'reply-all' },
	{ class: 'fab fa-replyd', name: 'replyd' },
	{ class: 'fas fa-republican', name: 'republican' },
	{ class: 'fab fa-researchgate', name: 'researchgate' },
	{ class: 'fab fa-resolving', name: 'resolving' },
	{ class: 'fas fa-restroom', name: 'restroom' },
	{ class: 'fas fa-retweet', name: 'retweet' },
	{ class: 'fab fa-rev', name: 'rev' },
	{ class: 'fas fa-ribbon', name: 'ribbon' },
	{ class: 'fas fa-ring', name: 'ring' },
	{ class: 'fas fa-road', name: 'road' },
	{ class: 'fas fa-robot', name: 'robot' },
	{ class: 'fas fa-rocket', name: 'rocket' },
	{ class: 'fab fa-rocketchat', name: 'rocketchat' },
	{ class: 'fab fa-rockrms', name: 'rockrms' },
	{ class: 'fas fa-route', name: 'route' },
	{ class: 'fas fa-rss', name: 'rss' },
	{ class: 'fas fa-rss-square', name: 'rss-square' },
	{ class: 'fas fa-ruble-sign', name: 'ruble-sign' },
	{ class: 'fas fa-ruler', name: 'ruler' },
	{ class: 'fas fa-ruler-combined', name: 'ruler-combined' },
	{ class: 'fas fa-ruler-horizontal', name: 'ruler-horizontal' },
	{ class: 'fas fa-ruler-vertical', name: 'ruler-vertical' },
	{ class: 'fas fa-running', name: 'running' },
	{ class: 'fas fa-rupee-sign', name: 'rupee-sign' },
	{ class: 'fab fa-rust', name: 'rust' },
	{ class: 'fas fa-sad-cry', name: 'sad-cry' },
	{ class: 'far fa-sad-cry', name: 'sad-cry' },
	{ class: 'fas fa-sad-tear', name: 'sad-tear' },
	{ class: 'far fa-sad-tear', name: 'sad-tear' },
	{ class: 'fab fa-safari', name: 'safari' },
	{ class: 'fab fa-salesforce', name: 'salesforce' },
	{ class: 'fab fa-sass', name: 'sass' },
	{ class: 'fas fa-satellite', name: 'satellite' },
	{ class: 'fas fa-satellite-dish', name: 'satellite-dish' },
	{ class: 'fas fa-save', name: 'save' },
	{ class: 'far fa-save', name: 'save' },
	{ class: 'fab fa-schlix', name: 'schlix' },
	{ class: 'fas fa-school', name: 'school' },
	{ class: 'fas fa-screwdriver', name: 'screwdriver' },
	{ class: 'fab fa-scribd', name: 'scribd' },
	{ class: 'fas fa-scroll', name: 'scroll' },
	{ class: 'fas fa-sd-card', name: 'sd-card' },
	{ class: 'fas fa-search', name: 'search' },
	{ class: 'fas fa-search-dollar', name: 'search-dollar' },
	{ class: 'fas fa-search-location', name: 'search-location' },
	{ class: 'fas fa-search-minus', name: 'search-minus' },
	{ class: 'fas fa-search-plus', name: 'search-plus' },
	{ class: 'fab fa-searchengin', name: 'searchengin' },
	{ class: 'fas fa-seedling', name: 'seedling' },
	{ class: 'fab fa-sellcast', name: 'sellcast' },
	{ class: 'fab fa-sellsy', name: 'sellsy' },
	{ class: 'fas fa-server', name: 'server' },
	{ class: 'fab fa-servicestack', name: 'servicestack' },
	{ class: 'fas fa-shapes', name: 'shapes' },
	{ class: 'fas fa-share', name: 'share' },
	{ class: 'fas fa-share-alt', name: 'share-alt' },
	{ class: 'fas fa-share-alt-square', name: 'share-alt-square' },
	{ class: 'fas fa-share-square', name: 'share-square' },
	{ class: 'far fa-share-square', name: 'share-square' },
	{ class: 'fas fa-shekel-sign', name: 'shekel-sign' },
	{ class: 'fas fa-shield-alt', name: 'shield-alt' },
	{ class: 'fas fa-shield-virus', name: 'shield-virus' },
	{ class: 'fas fa-ship', name: 'ship' },
	{ class: 'fas fa-shipping-fast', name: 'shipping-fast' },
	{ class: 'fab fa-shirtsinbulk', name: 'shirtsinbulk' },
	{ class: 'fas fa-shoe-prints', name: 'shoe-prints' },
	{ class: 'fab fa-shopify', name: 'shopify' },
	{ class: 'fas fa-shopping-bag', name: 'shopping-bag' },
	{ class: 'fas fa-shopping-basket', name: 'shopping-basket' },
	{ class: 'fas fa-shopping-cart', name: 'shopping-cart' },
	{ class: 'fab fa-shopware', name: 'shopware' },
	{ class: 'fas fa-shower', name: 'shower' },
	{ class: 'fas fa-shuttle-van', name: 'shuttle-van' },
	{ class: 'fas fa-sign', name: 'sign' },
	{ class: 'fas fa-sign-in-alt', name: 'sign-in-alt' },
	{ class: 'fas fa-sign-language', name: 'sign-language' },
	{ class: 'fas fa-sign-out-alt', name: 'sign-out-alt' },
	{ class: 'fas fa-signal', name: 'signal' },
	{ class: 'fas fa-signature', name: 'signature' },
	{ class: 'fas fa-sim-card', name: 'sim-card' },
	{ class: 'fab fa-simplybuilt', name: 'simplybuilt' },
	{ class: 'fas fa-sink', name: 'sink' },
	{ class: 'fab fa-sistrix', name: 'sistrix' },
	{ class: 'fas fa-sitemap', name: 'sitemap' },
	{ class: 'fab fa-sith', name: 'sith' },
	{ class: 'fas fa-skating', name: 'skating' },
	{ class: 'fab fa-sketch', name: 'sketch' },
	{ class: 'fas fa-skiing', name: 'skiing' },
	{ class: 'fas fa-skiing-nordic', name: 'skiing-nordic' },
	{ class: 'fas fa-skull', name: 'skull' },
	{ class: 'fas fa-skull-crossbones', name: 'skull-crossbones' },
	{ class: 'fab fa-skyatlas', name: 'skyatlas' },
	{ class: 'fab fa-skype', name: 'skype' },
	{ class: 'fab fa-slack', name: 'slack' },
	{ class: 'fab fa-slack-hash', name: 'slack-hash' },
	{ class: 'fas fa-slash', name: 'slash' },
	{ class: 'fas fa-sleigh', name: 'sleigh' },
	{ class: 'fas fa-sliders-h', name: 'sliders-h' },
	{ class: 'fab fa-slideshare', name: 'slideshare' },
	{ class: 'fas fa-smile', name: 'smile' },
	{ class: 'far fa-smile', name: 'smile' },
	{ class: 'fas fa-smile-beam', name: 'smile-beam' },
	{ class: 'far fa-smile-beam', name: 'smile-beam' },
	{ class: 'fas fa-smile-wink', name: 'smile-wink' },
	{ class: 'far fa-smile-wink', name: 'smile-wink' },
	{ class: 'fas fa-smog', name: 'smog' },
	{ class: 'fas fa-smoking', name: 'smoking' },
	{ class: 'fas fa-smoking-ban', name: 'smoking-ban' },
	{ class: 'fas fa-sms', name: 'sms' },
	{ class: 'fab fa-snapchat', name: 'snapchat' },
	{ class: 'fab fa-snapchat-ghost', name: 'snapchat-ghost' },
	{ class: 'fab fa-snapchat-square', name: 'snapchat-square' },
	{ class: 'fas fa-snowboarding', name: 'snowboarding' },
	{ class: 'fas fa-snowflake', name: 'snowflake' },
	{ class: 'far fa-snowflake', name: 'snowflake' },
	{ class: 'fas fa-snowman', name: 'snowman' },
	{ class: 'fas fa-snowplow', name: 'snowplow' },
	{ class: 'fas fa-soap', name: 'soap' },
	{ class: 'fas fa-socks', name: 'socks' },
	{ class: 'fas fa-solar-panel', name: 'solar-panel' },
	{ class: 'fas fa-sort', name: 'sort' },
	{ class: 'fas fa-sort-alpha-down', name: 'sort-alpha-down' },
	{ class: 'fas fa-sort-alpha-down-alt', name: 'sort-alpha-down-alt' },
	{ class: 'fas fa-sort-alpha-up', name: 'sort-alpha-up' },
	{ class: 'fas fa-sort-alpha-up-alt', name: 'sort-alpha-up-alt' },
	{ class: 'fas fa-sort-amount-down', name: 'sort-amount-down' },
	{ class: 'fas fa-sort-amount-down-alt', name: 'sort-amount-down-alt' },
	{ class: 'fas fa-sort-amount-up', name: 'sort-amount-up' },
	{ class: 'fas fa-sort-amount-up-alt', name: 'sort-amount-up-alt' },
	{ class: 'fas fa-sort-down', name: 'sort-down' },
	{ class: 'fas fa-sort-numeric-down', name: 'sort-numeric-down' },
	{ class: 'fas fa-sort-numeric-down-alt', name: 'sort-numeric-down-alt' },
	{ class: 'fas fa-sort-numeric-up', name: 'sort-numeric-up' },
	{ class: 'fas fa-sort-numeric-up-alt', name: 'sort-numeric-up-alt' },
	{ class: 'fas fa-sort-up', name: 'sort-up' },
	{ class: 'fab fa-soundcloud', name: 'soundcloud' },
	{ class: 'fab fa-sourcetree', name: 'sourcetree' },
	{ class: 'fas fa-spa', name: 'spa' },
	{ class: 'fas fa-space-shuttle', name: 'space-shuttle' },
	{ class: 'fab fa-speakap', name: 'speakap' },
	{ class: 'fab fa-speaker-deck', name: 'speaker-deck' },
	{ class: 'fas fa-spell-check', name: 'spell-check' },
	{ class: 'fas fa-spider', name: 'spider' },
	{ class: 'fas fa-spinner', name: 'spinner' },
	{ class: 'fas fa-splotch', name: 'splotch' },
	{ class: 'fab fa-spotify', name: 'spotify' },
	{ class: 'fas fa-spray-can', name: 'spray-can' },
	{ class: 'fas fa-square', name: 'square' },
	{ class: 'far fa-square', name: 'square' },
	{ class: 'fas fa-square-full', name: 'square-full' },
	{ class: 'fas fa-square-root-alt', name: 'square-root-alt' },
	{ class: 'fab fa-squarespace', name: 'squarespace' },
	{ class: 'fab fa-stack-exchange', name: 'stack-exchange' },
	{ class: 'fab fa-stack-overflow', name: 'stack-overflow' },
	{ class: 'fab fa-stackpath', name: 'stackpath' },
	{ class: 'fas fa-stamp', name: 'stamp' },
	{ class: 'fas fa-star', name: 'star' },
	{ class: 'far fa-star', name: 'star' },
	{ class: 'fas fa-star-and-crescent', name: 'star-and-crescent' },
	{ class: 'fas fa-star-half', name: 'star-half' },
	{ class: 'far fa-star-half', name: 'star-half' },
	{ class: 'fas fa-star-half-alt', name: 'star-half-alt' },
	{ class: 'fas fa-star-of-david', name: 'star-of-david' },
	{ class: 'fas fa-star-of-life', name: 'star-of-life' },
	{ class: 'fab fa-staylinked', name: 'staylinked' },
	{ class: 'fab fa-steam', name: 'steam' },
	{ class: 'fab fa-steam-square', name: 'steam-square' },
	{ class: 'fab fa-steam-symbol', name: 'steam-symbol' },
	{ class: 'fas fa-step-backward', name: 'step-backward' },
	{ class: 'fas fa-step-forward', name: 'step-forward' },
	{ class: 'fas fa-stethoscope', name: 'stethoscope' },
	{ class: 'fab fa-sticker-mule', name: 'sticker-mule' },
	{ class: 'fas fa-sticky-note', name: 'sticky-note' },
	{ class: 'far fa-sticky-note', name: 'sticky-note' },
	{ class: 'fas fa-stop', name: 'stop' },
	{ class: 'fas fa-stop-circle', name: 'stop-circle' },
	{ class: 'far fa-stop-circle', name: 'stop-circle' },
	{ class: 'fas fa-stopwatch', name: 'stopwatch' },
	{ class: 'fas fa-stopwatch-20', name: 'stopwatch-20' },
	{ class: 'fas fa-store', name: 'store' },
	{ class: 'fas fa-store-alt', name: 'store-alt' },
	{ class: 'fas fa-store-alt-slash', name: 'store-alt-slash' },
	{ class: 'fas fa-store-slash', name: 'store-slash' },
	{ class: 'fab fa-strava', name: 'strava' },
	{ class: 'fas fa-stream', name: 'stream' },
	{ class: 'fas fa-street-view', name: 'street-view' },
	{ class: 'fas fa-strikethrough', name: 'strikethrough' },
	{ class: 'fab fa-stripe', name: 'stripe' },
	{ class: 'fab fa-stripe-s', name: 'stripe-s' },
	{ class: 'fas fa-stroopwafel', name: 'stroopwafel' },
	{ class: 'fab fa-studiovinari', name: 'studiovinari' },
	{ class: 'fab fa-stumbleupon', name: 'stumbleupon' },
	{ class: 'fab fa-stumbleupon-circle', name: 'stumbleupon-circle' },
	{ class: 'fas fa-subscript', name: 'subscript' },
	{ class: 'fas fa-subway', name: 'subway' },
	{ class: 'fas fa-suitcase', name: 'suitcase' },
	{ class: 'fas fa-suitcase-rolling', name: 'suitcase-rolling' },
	{ class: 'fas fa-sun', name: 'sun' },
	{ class: 'far fa-sun', name: 'sun' },
	{ class: 'fab fa-superpowers', name: 'superpowers' },
	{ class: 'fas fa-superscript', name: 'superscript' },
	{ class: 'fab fa-supple', name: 'supple' },
	{ class: 'fas fa-surprise', name: 'surprise' },
	{ class: 'far fa-surprise', name: 'surprise' },
	{ class: 'fab fa-suse', name: 'suse' },
	{ class: 'fas fa-swatchbook', name: 'swatchbook' },
	{ class: 'fab fa-swift', name: 'swift' },
	{ class: 'fas fa-swimmer', name: 'swimmer' },
	{ class: 'fas fa-swimming-pool', name: 'swimming-pool' },
	{ class: 'fab fa-symfony', name: 'symfony' },
	{ class: 'fas fa-synagogue', name: 'synagogue' },
	{ class: 'fas fa-sync', name: 'sync' },
	{ class: 'fas fa-sync-alt', name: 'sync-alt' },
	{ class: 'fas fa-syringe', name: 'syringe' },
	{ class: 'fas fa-table', name: 'table' },
	{ class: 'fas fa-table-tennis', name: 'table-tennis' },
	{ class: 'fas fa-tablet', name: 'tablet' },
	{ class: 'fas fa-tablet-alt', name: 'tablet-alt' },
	{ class: 'fas fa-tablets', name: 'tablets' },
	{ class: 'fas fa-tachometer-alt', name: 'tachometer-alt' },
	{ class: 'fas fa-tag', name: 'tag' },
	{ class: 'fas fa-tags', name: 'tags' },
	{ class: 'fas fa-tape', name: 'tape' },
	{ class: 'fas fa-tasks', name: 'tasks' },
	{ class: 'fas fa-taxi', name: 'taxi' },
	{ class: 'fab fa-teamspeak', name: 'teamspeak' },
	{ class: 'fas fa-teeth', name: 'teeth' },
	{ class: 'fas fa-teeth-open', name: 'teeth-open' },
	{ class: 'fab fa-telegram', name: 'telegram' },
	{ class: 'fab fa-telegram-plane', name: 'telegram-plane' },
	{ class: 'fas fa-temperature-high', name: 'temperature-high' },
	{ class: 'fas fa-temperature-low', name: 'temperature-low' },
	{ class: 'fab fa-tencent-weibo', name: 'tencent-weibo' },
	{ class: 'fas fa-tenge', name: 'tenge' },
	{ class: 'fas fa-terminal', name: 'terminal' },
	{ class: 'fas fa-text-height', name: 'text-height' },
	{ class: 'fas fa-text-width', name: 'text-width' },
	{ class: 'fas fa-th', name: 'th' },
	{ class: 'fas fa-th-large', name: 'th-large' },
	{ class: 'fas fa-th-list', name: 'th-list' },
	{ class: 'fab fa-the-red-yeti', name: 'the-red-yeti' },
	{ class: 'fas fa-theater-masks', name: 'theater-masks' },
	{ class: 'fab fa-themeco', name: 'themeco' },
	{ class: 'fab fa-themeisle', name: 'themeisle' },
	{ class: 'fas fa-thermometer', name: 'thermometer' },
	{ class: 'fas fa-thermometer-empty', name: 'thermometer-empty' },
	{ class: 'fas fa-thermometer-full', name: 'thermometer-full' },
	{ class: 'fas fa-thermometer-half', name: 'thermometer-half' },
	{ class: 'fas fa-thermometer-quarter', name: 'thermometer-quarter' },
	{ class: 'fas fa-thermometer-three-quarters', name: 'thermometer-three-quarters' },
	{ class: 'fab fa-think-peaks', name: 'think-peaks' },
	{ class: 'fas fa-thumbs-down', name: 'thumbs-down' },
	{ class: 'far fa-thumbs-down', name: 'thumbs-down' },
	{ class: 'fas fa-thumbs-up', name: 'thumbs-up' },
	{ class: 'far fa-thumbs-up', name: 'thumbs-up' },
	{ class: 'fas fa-thumbtack', name: 'thumbtack' },
	{ class: 'fas fa-ticket-alt', name: 'ticket-alt' },
	{ class: 'fab fa-tiktok', name: 'tiktok' },
	{ class: 'fas fa-times', name: 'times' },
	{ class: 'fas fa-times-circle', name: 'times-circle' },
	{ class: 'far fa-times-circle', name: 'times-circle' },
	{ class: 'fas fa-tint', name: 'tint' },
	{ class: 'fas fa-tint-slash', name: 'tint-slash' },
	{ class: 'fas fa-tired', name: 'tired' },
	{ class: 'far fa-tired', name: 'tired' },
	{ class: 'fas fa-toggle-off', name: 'toggle-off' },
	{ class: 'fas fa-toggle-on', name: 'toggle-on' },
	{ class: 'fas fa-toilet', name: 'toilet' },
	{ class: 'fas fa-toilet-paper', name: 'toilet-paper' },
	{ class: 'fas fa-toilet-paper-slash', name: 'toilet-paper-slash' },
	{ class: 'fas fa-toolbox', name: 'toolbox' },
	{ class: 'fas fa-tools', name: 'tools' },
	{ class: 'fas fa-tooth', name: 'tooth' },
	{ class: 'fas fa-torah', name: 'torah' },
	{ class: 'fas fa-torii-gate', name: 'torii-gate' },
	{ class: 'fas fa-tractor', name: 'tractor' },
	{ class: 'fab fa-trade-federation', name: 'trade-federation' },
	{ class: 'fas fa-trademark', name: 'trademark' },
	{ class: 'fas fa-traffic-light', name: 'traffic-light' },
	{ class: 'fas fa-trailer', name: 'trailer' },
	{ class: 'fas fa-train', name: 'train' },
	{ class: 'fas fa-tram', name: 'tram' },
	{ class: 'fas fa-transgender', name: 'transgender' },
	{ class: 'fas fa-transgender-alt', name: 'transgender-alt' },
	{ class: 'fas fa-trash', name: 'trash' },
	{ class: 'fas fa-trash-alt', name: 'trash-alt' },
	{ class: 'far fa-trash-alt', name: 'trash-alt' },
	{ class: 'fas fa-trash-restore', name: 'trash-restore' },
	{ class: 'fas fa-trash-restore-alt', name: 'trash-restore-alt' },
	{ class: 'fas fa-tree', name: 'tree' },
	{ class: 'fab fa-trello', name: 'trello' },
	{ class: 'fas fa-trophy', name: 'trophy' },
	{ class: 'fas fa-truck', name: 'truck' },
	{ class: 'fas fa-truck-loading', name: 'truck-loading' },
	{ class: 'fas fa-truck-monster', name: 'truck-monster' },
	{ class: 'fas fa-truck-moving', name: 'truck-moving' },
	{ class: 'fas fa-truck-pickup', name: 'truck-pickup' },
	{ class: 'fas fa-tshirt', name: 'tshirt' },
	{ class: 'fas fa-tty', name: 'tty' },
	{ class: 'fab fa-tumblr', name: 'tumblr' },
	{ class: 'fab fa-tumblr-square', name: 'tumblr-square' },
	{ class: 'fas fa-tv', name: 'tv' },
	{ class: 'fab fa-twitch', name: 'twitch' },
	{ class: 'fab fa-twitter', name: 'twitter' },
	{ class: 'fab fa-twitter-square', name: 'twitter-square' },
	{ class: 'fab fa-typo3', name: 'typo3' },
	{ class: 'fab fa-uber', name: 'uber' },
	{ class: 'fab fa-ubuntu', name: 'ubuntu' },
	{ class: 'fab fa-uikit', name: 'uikit' },
	{ class: 'fab fa-umbraco', name: 'umbraco' },
	{ class: 'fas fa-umbrella', name: 'umbrella' },
	{ class: 'fas fa-umbrella-beach', name: 'umbrella-beach' },
	{ class: 'fab fa-uncharted', name: 'uncharted' },
	{ class: 'fas fa-underline', name: 'underline' },
	{ class: 'fas fa-undo', name: 'undo' },
	{ class: 'fas fa-undo-alt', name: 'undo-alt' },
	{ class: 'fab fa-uniregistry', name: 'uniregistry' },
	{ class: 'fab fa-unity', name: 'unity' },
	{ class: 'fas fa-universal-access', name: 'universal-access' },
	{ class: 'fas fa-university', name: 'university' },
	{ class: 'fas fa-unlink', name: 'unlink' },
	{ class: 'fas fa-unlock', name: 'unlock' },
	{ class: 'fas fa-unlock-alt', name: 'unlock-alt' },
	{ class: 'fab fa-unsplash', name: 'unsplash' },
	{ class: 'fab fa-untappd', name: 'untappd' },
	{ class: 'fas fa-upload', name: 'upload' },
	{ class: 'fab fa-ups', name: 'ups' },
	{ class: 'fab fa-usb', name: 'usb' },
	{ class: 'fas fa-user', name: 'user' },
	{ class: 'far fa-user', name: 'user' },
	{ class: 'fas fa-user-alt', name: 'user-alt' },
	{ class: 'fas fa-user-alt-slash', name: 'user-alt-slash' },
	{ class: 'fas fa-user-astronaut', name: 'user-astronaut' },
	{ class: 'fas fa-user-check', name: 'user-check' },
	{ class: 'fas fa-user-circle', name: 'user-circle' },
	{ class: 'far fa-user-circle', name: 'user-circle' },
	{ class: 'fas fa-user-clock', name: 'user-clock' },
	{ class: 'fas fa-user-cog', name: 'user-cog' },
	{ class: 'fas fa-user-edit', name: 'user-edit' },
	{ class: 'fas fa-user-friends', name: 'user-friends' },
	{ class: 'fas fa-user-graduate', name: 'user-graduate' },
	{ class: 'fas fa-user-injured', name: 'user-injured' },
	{ class: 'fas fa-user-lock', name: 'user-lock' },
	{ class: 'fas fa-user-md', name: 'user-md' },
	{ class: 'fas fa-user-minus', name: 'user-minus' },
	{ class: 'fas fa-user-ninja', name: 'user-ninja' },
	{ class: 'fas fa-user-nurse', name: 'user-nurse' },
	{ class: 'fas fa-user-plus', name: 'user-plus' },
	{ class: 'fas fa-user-secret', name: 'user-secret' },
	{ class: 'fas fa-user-shield', name: 'user-shield' },
	{ class: 'fas fa-user-slash', name: 'user-slash' },
	{ class: 'fas fa-user-tag', name: 'user-tag' },
	{ class: 'fas fa-user-tie', name: 'user-tie' },
	{ class: 'fas fa-user-times', name: 'user-times' },
	{ class: 'fas fa-users', name: 'users' },
	{ class: 'fas fa-users-cog', name: 'users-cog' },
	{ class: 'fas fa-users-slash', name: 'users-slash' },
	{ class: 'fab fa-usps', name: 'usps' },
	{ class: 'fab fa-ussunnah', name: 'ussunnah' },
	{ class: 'fas fa-utensil-spoon', name: 'utensil-spoon' },
	{ class: 'fas fa-utensils', name: 'utensils' },
	{ class: 'fab fa-vaadin', name: 'vaadin' },
	{ class: 'fas fa-vector-square', name: 'vector-square' },
	{ class: 'fas fa-venus', name: 'venus' },
	{ class: 'fas fa-venus-double', name: 'venus-double' },
	{ class: 'fas fa-venus-mars', name: 'venus-mars' },
	{ class: 'fas fa-vest', name: 'vest' },
	{ class: 'fas fa-vest-patches', name: 'vest-patches' },
	{ class: 'fab fa-viacoin', name: 'viacoin' },
	{ class: 'fab fa-viadeo', name: 'viadeo' },
	{ class: 'fab fa-viadeo-square', name: 'viadeo-square' },
	{ class: 'fas fa-vial', name: 'vial' },
	{ class: 'fas fa-vials', name: 'vials' },
	{ class: 'fab fa-viber', name: 'viber' },
	{ class: 'fas fa-video', name: 'video' },
	{ class: 'fas fa-video-slash', name: 'video-slash' },
	{ class: 'fas fa-vihara', name: 'vihara' },
	{ class: 'fab fa-vimeo', name: 'vimeo' },
	{ class: 'fab fa-vimeo-square', name: 'vimeo-square' },
	{ class: 'fab fa-vimeo-v', name: 'vimeo-v' },
	{ class: 'fab fa-vine', name: 'vine' },
	{ class: 'fas fa-virus', name: 'virus' },
	{ class: 'fas fa-virus-slash', name: 'virus-slash' },
	{ class: 'fas fa-viruses', name: 'viruses' },
	{ class: 'fab fa-vk', name: 'vk' },
	{ class: 'fab fa-vnv', name: 'vnv' },
	{ class: 'fas fa-voicemail', name: 'voicemail' },
	{ class: 'fas fa-volleyball-ball', name: 'volleyball-ball' },
	{ class: 'fas fa-volume-down', name: 'volume-down' },
	{ class: 'fas fa-volume-mute', name: 'volume-mute' },
	{ class: 'fas fa-volume-off', name: 'volume-off' },
	{ class: 'fas fa-volume-up', name: 'volume-up' },
	{ class: 'fas fa-vote-yea', name: 'vote-yea' },
	{ class: 'fas fa-vr-cardboard', name: 'vr-cardboard' },
	{ class: 'fab fa-vuejs', name: 'vuejs' },
	{ class: 'fas fa-walking', name: 'walking' },
	{ class: 'fas fa-wallet', name: 'wallet' },
	{ class: 'fas fa-warehouse', name: 'warehouse' },
	{ class: 'fab fa-watchman-monitoring', name: 'watchman-monitoring' },
	{ class: 'fas fa-water', name: 'water' },
	{ class: 'fas fa-wave-square', name: 'wave-square' },
	{ class: 'fab fa-waze', name: 'waze' },
	{ class: 'fab fa-weebly', name: 'weebly' },
	{ class: 'fab fa-weibo', name: 'weibo' },
	{ class: 'fas fa-weight', name: 'weight' },
	{ class: 'fas fa-weight-hanging', name: 'weight-hanging' },
	{ class: 'fab fa-weixin', name: 'weixin' },
	{ class: 'fab fa-whatsapp', name: 'whatsapp' },
	{ class: 'fab fa-whatsapp-square', name: 'whatsapp-square' },
	{ class: 'fas fa-wheelchair', name: 'wheelchair' },
	{ class: 'fab fa-whmcs', name: 'whmcs' },
	{ class: 'fas fa-wifi', name: 'wifi' },
	{ class: 'fab fa-wikipedia-w', name: 'wikipedia-w' },
	{ class: 'fas fa-wind', name: 'wind' },
	{ class: 'fas fa-window-close', name: 'window-close' },
	{ class: 'far fa-window-close', name: 'window-close' },
	{ class: 'fas fa-window-maximize', name: 'window-maximize' },
	{ class: 'far fa-window-maximize', name: 'window-maximize' },
	{ class: 'fas fa-window-minimize', name: 'window-minimize' },
	{ class: 'far fa-window-minimize', name: 'window-minimize' },
	{ class: 'fas fa-window-restore', name: 'window-restore' },
	{ class: 'far fa-window-restore', name: 'window-restore' },
	{ class: 'fab fa-windows', name: 'windows' },
	{ class: 'fas fa-wine-bottle', name: 'wine-bottle' },
	{ class: 'fas fa-wine-glass', name: 'wine-glass' },
	{ class: 'fas fa-wine-glass-alt', name: 'wine-glass-alt' },
	{ class: 'fab fa-wix', name: 'wix' },
	{ class: 'fab fa-wizards-of-the-coast', name: 'wizards-of-the-coast' },
	{ class: 'fab fa-wodu', name: 'wodu' },
	{ class: 'fab fa-wolf-pack-battalion', name: 'wolf-pack-battalion' },
	{ class: 'fas fa-won-sign', name: 'won-sign' },
	{ class: 'fab fa-wordpress', name: 'wordpress' },
	{ class: 'fab fa-wordpress-simple', name: 'wordpress-simple' },
	{ class: 'fab fa-wpbeginner', name: 'wpbeginner' },
	{ class: 'fab fa-wpexplorer', name: 'wpexplorer' },
	{ class: 'fab fa-wpforms', name: 'wpforms' },
	{ class: 'fab fa-wpressr', name: 'wpressr' },
	{ class: 'fas fa-wrench', name: 'wrench' },
	{ class: 'fas fa-x-ray', name: 'x-ray' },
	{ class: 'fab fa-xbox', name: 'xbox' },
	{ class: 'fab fa-xing', name: 'xing' },
	{ class: 'fab fa-xing-square', name: 'xing-square' },
	{ class: 'fab fa-y-combinator', name: 'y-combinator' },
	{ class: 'fab fa-yahoo', name: 'yahoo' },
	{ class: 'fab fa-yammer', name: 'yammer' },
	{ class: 'fab fa-yandex', name: 'yandex' },
	{ class: 'fab fa-yandex-international', name: 'yandex-international' },
	{ class: 'fab fa-yarn', name: 'yarn' },
	{ class: 'fab fa-yelp', name: 'yelp' },
	{ class: 'fas fa-yen-sign', name: 'yen-sign' },
	{ class: 'fas fa-yin-yang', name: 'yin-yang' },
	{ class: 'fab fa-yoast', name: 'yoast' },
	{ class: 'fab fa-youtube', name: 'youtube' },
	{ class: 'fab fa-youtube-square', name: 'youtube-square' },
	{ class: 'fab fa-zhihu', name: 'zhihu' },
];
/**
 * @props className (optional): 'mt20' (String)
 * @props label (optional): 'Select Icon' (String)
 * @props icon (required): { class, fontSize, color } (Object)
 * @props onChange (required): (Function)
 * @props defaults (optional): { class, fontSize, color } (Object)
 */

import { __ } from '@wordpress/i18n';
import { useState, useEffect } from '@wordpress/element';
import { PanelRow, RangeControl, Tooltip, __experimentalGradientPicker, GradientPicker } from '@wordpress/components';
const Gradient = __experimentalGradientPicker || GradientPicker;

import './IconControl.scss';

import Title from './Title';
import BtnGroup from './BtnGroup';
import BColor from './BColor';
import options from './Helper/options';
const { gradients, bgTypes } = options;

import iconLists from './Helper/iconLists';

const IconControl = props => {
	const { className = '', label = __('Select Icon:', 'bplugins'), icon = {}, onChange, defaults = {}, isSize = true, isColor = true } = props;

	const defaultVal = { class: '', name: '', fontSize: 16, colorType: 'solid', color: 'inherit', gradient: 'linear-gradient(135deg, #4527a4, #8344c5)' }

	const getDefault = property => defaults?.[property] || defaultVal[property];

	const getValue = property => icon?.[property] || getDefault(property);
	const setValue = (property, val) => onChange({ ...icon, [property]: val });

	// Font family searching
	const [query, setQuery] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const searchIcons = iconLists.filter(({ name }) => name.replace(/-/g, ' ').toLowerCase().includes(query.toLowerCase()));

	useEffect(() => {
		const allStyles = `${undefined == icon?.fontSize || !isSize ? '' : `font-size: ${icon?.fontSize}px;`}
		${'gradient' === getValue('colorType') ? `color: transparent; background-image: ${getValue('gradient')}; -webkit-background-clip: text; background-clip: text;` : `color: ${getValue('color')};`}`;

		// Initial Obj
		const initObj = { class: getValue('class'), name: getValue('name'), fontSize: getValue('fontSize'), colorType: getValue('colorType'), color: getValue('color'), gradient: getValue('gradient') }

		onChange({ ...icon, styles: allStyles.replace(/\s+/g, ' ').trim(), ...initObj });
	}, [icon?.class, icon?.name, icon?.fontSize, icon?.colorType, icon?.color, icon?.gradient]);

	return <>
		{/* Select Icon */}
		<PanelRow className={`bPlIconTitle ${className}`}>
			<Title mt='0' mb='0'>{label}</Title>
			<i className={icon?.class}></i>
		</PanelRow>
		<div className='bPlIconSelect'>
			<input type='search' value={query} onClick={() => setIsSearching(!isSearching)} placeholder={getValue('name')?.replace(/-/g, ' ') || 'Search & Select Icon'} onChange={e => setQuery(e.target.value)} />

			<span className={`dashicon dashicons dashicons-${isSearching ? 'arrow-up' : 'arrow-down'}`} onClick={() => setIsSearching(!isSearching)}></span>

			{isSearching && <div className='bPlIconLists'>
				{searchIcons?.map(item => <Tooltip key={item?.class} text={item?.name?.replace(/-/g, ' ')} position='top'><i onClick={() => {
					onChange({ ...icon, ['class']: item?.class, ['name']: item?.name });
					setQuery('');
					setIsSearching(false);
				}} className={item?.class}></i></Tooltip>)}
			</div>}
		</div>

		{isSize && <>
			{/* Icon Size */}
			<Title>{__('Icon Size:', 'bplugins')}</Title>
			<RangeControl value={getValue('fontSize')} onChange={val => setValue('fontSize', val)} min={0} max={400} step={1} allowReset={true} resetFallbackValue={getDefault('fontSize')} initialPosition={getDefault('fontSize')} />
		</>}

		{isColor && <>
			<PanelRow className='mt20'>
				<Title mt='0' mb='0'>{__('Icon Color Type:', 'bplugins')}</Title>
				<BtnGroup value={getValue('colorType')} onChange={val => setValue('colorType', val)} options={bgTypes} size='small' />
			</PanelRow>

			{'gradient' === getValue('colorType') ? <Gradient value={getValue('gradient')} onChange={val => setValue('gradient', val)} gradients={gradients} /> : <BColor label={__('Icon Color:', 'bplugins')} value={getValue('color')} onChange={val => setValue('color', val)} defaultColor={getDefault('color')} />}
		</>}
	</>;
};
export default IconControl;
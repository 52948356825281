import { __ } from '@wordpress/i18n';
import { useEffect, useRef } from '@wordpress/element';
import { RichText } from '@wordpress/block-editor';

import Settings from './Settings';
import Style from './Style';

const Edit = props => {
	const { className, attributes, setAttributes, clientId } = props;
	const { text, tooltip, icon, animationType, animationDuration } = attributes;

	useEffect(() => { clientId && setAttributes({ cId: clientId.substring(0, 10) }); }, [clientId]); // Set & Update clientId to cId

	const buttonEl = useRef(null);

	useEffect(() => {
		AOS.init();
	}, []);

	useEffect(() => {
		buttonEl.current.classList.remove('aos-init');
		buttonEl.current.classList.remove('aos-animate');

		setTimeout(() => {
			buttonEl.current.classList.add('aos-init');
			buttonEl.current.classList.add('aos-animate');
		}, 500);
	}, [animationType, animationDuration]);

	return <>
		<Settings attributes={attributes} setAttributes={setAttributes} />

		<div className={className} id={`btnButton-${clientId}`}>
			<Style attributes={attributes} clientId={clientId} />


			<a
				className='btnButton'
				data-aos={animationType}
				data-aos-duration={animationDuration * 1000}
				data-aos-mirror='true'
				tooltip={tooltip}
				position='top'
				ref={buttonEl}
			>
				{icon?.class && <i className={icon?.class}></i>}

				<RichText className='btnText' tagName='span' value={text} onChange={val => setAttributes({ text: val })} placeholder={__('Button Text', 'button-block')} inlineToolbar />
			</a>
		</div>
	</>;
};
export default Edit;
import { __ } from '@wordpress/i18n';

const options = {
	targets: [
		{ label: __('Self', 'button-block'), value: '_self', icon: 'SELF' },
		{ label: __('New Tab', 'button-block'), value: '_blank', icon: 'NEW' }
	],

	buttonTypes: [
		{ label: __('Flat', 'button-block'), value: 'flat', icon: 'Flat' },
		{ label: __('3D', 'button-block'), value: '3d', icon: '3D' }
	],

	buttonSizes: [
		{ label: __('Small', 'button-block'), value: 'small' },
		{ label: __('Medium', 'button-block'), value: 'medium' },
		{ label: __('Large', 'button-block'), value: 'large' },
		{ label: __('X Large', 'button-block'), value: 'x-large' },
	],

	animationTypes: [
		{ label: __('None', 'button-block'), value: '' },
		{ label: __('Fade', 'button-block'), value: 'fade' },
		{ label: __('Fade Up', 'button-block'), value: 'fade-up' },
		{ label: __('Fade Down', 'button-block'), value: 'fade-down' },
		{ label: __('Fade Left', 'button-block'), value: 'fade-left' },
		{ label: __('Fade Right', 'button-block'), value: 'fade-right' },
		{ label: __('Flip Up', 'button-block'), value: 'flip-up' },
		{ label: __('Flip Down', 'button-block'), value: 'flip-down' },
		{ label: __('Flip Left', 'button-block'), value: 'flip-left' },
		{ label: __('Flip Right', 'button-block'), value: 'flip-right' },
		{ label: __('Zoom In', 'button-block'), value: 'zoom-in' },
		{ label: __('Zoom In Up', 'button-block'), value: 'zoom-in-up' },
		{ label: __('Zoom In Down', 'button-block'), value: 'zoom-in-down' },
		{ label: __('Zoom In Left', 'button-block'), value: 'zoom-in-left' },
		{ label: __('Zoom In Right', 'button-block'), value: 'zoom-in-right' }
	],

	generalStyleTabs: [
		{ name: 'general', title: __('General', 'button-block') },
		{ name: 'style', title: __('Style', 'button-block') }
	],

	pxUnit: { value: 'px', label: 'px', default: 0 },
	perUnit: { value: '%', label: '%', default: 0 },
	emUnit: { value: 'em', label: 'em', default: 0 },
	remUnit: { value: 'rem', label: 'rem', default: 0 },
	vwUnit: { value: 'vw', label: 'vw', default: 0 },
	vhUnit: { value: 'vh', label: 'vh', default: 0 }
}
export default options;